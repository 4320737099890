<template>
    <v-app id="inspire" style="background-color: #F2F2F2;">
        <z-container>

          <v-row row>
              <v-col cols="12" sm="8" offset-sm="2">
                  <v-card>
                      <v-toolbar color="primary" dark>
                        <!-- <v-toolbar-side-icon></v-toolbar-side-icon> -->
                        <v-toolbar-title>{{ this.$store.state.titulo_tela }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon :loading="loading" @click="refresh">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                        <v-btn icon @click="adicionar">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <!-- <v-btn icon @click="$vuetify.goTo($refs.busca, {offset: -50}); $refs.busca.focus()">
                            <v-icon>search</v-icon>
                        </v-btn> -->
                        <v-btn icon @click="dev = !dev">
                            <v-icon>mdi-code-tags</v-icon>
                        </v-btn>
                        <!-- <v-btn icon @click="podeapagar = !podeapagar">
                            <v-icon>delete</v-icon>
                        </v-btn> -->
                      </v-toolbar>

                      <v-container>
                        <v-row>
                          <v-col xs12>
                            <z-text-field ref="busca" solo hide-details clearable autofocus label="Pesquisar" v-model="busca"></z-text-field>
                          </v-col>
                        </v-row>
                      </v-container>

                      <v-card-text style="overflow-y: auto" class="fill-height px-0">
                        <v-list subheader three-line>
                          <template v-for="(configuracao, index) in configuracoes">
                            <v-list-item :key="configuracao.id" v-if="configuracao.tipo == 'string'" @click.stop="editar(configuracao)">
                              <v-list-item-action v-show="podeapagar">
                                <v-btn icon @click.stop="fazerNada">
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </v-list-item-action>
                              <v-list-item-action v-show="dev">
                                <v-btn icon @click.stop="copiarTexto(configuracao.config)">
                                  <v-icon>mdi-content-copy</v-icon>
                                </v-btn>
                              </v-list-item-action>

                              <v-list-item-content>
                                <v-list-item-title v-if="dev" :id="configuracao.config" style="user-select: auto">{{ configuracao.config }}</v-list-item-title>
                                <v-list-item-title v-else>{{ configuracao.descricao }}</v-list-item-title>
                                <v-list-item-subtitle>{{ configuracao.ajuda }}</v-list-item-subtitle>
                                <v-list-item-subtitle class="primary--text">{{ configuracao.valor }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item :key="configuracao.id" v-if="configuracao.tipo == 'float' || configuracao.tipo == 'integer'" @click.stop="editar(configuracao)">
                              <v-list-item-action v-show="podeapagar">
                                <v-btn icon @click.stop="fazerNada">
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </v-list-item-action>
                              <v-list-item-action v-show="dev">
                                <v-btn icon @click.stop="copiarTexto(configuracao.config)">
                                  <v-icon>mdi-content-copy</v-icon>
                                </v-btn>
                              </v-list-item-action>

                              <v-list-item-content>
                                <v-list-item-title v-if="dev" :id="configuracao.config" style="user-select: auto">{{ configuracao.config }}</v-list-item-title>
                                <v-list-item-title v-else>{{ configuracao.descricao }}</v-list-item-title>
                                <v-list-item-subtitle>{{ configuracao.ajuda }}</v-list-item-subtitle>
                                <v-list-item-subtitle class="primary--text" v-if="configuracao.tipo == 'float'">{{ configuracao.valor | valorBR }}</v-list-item-subtitle>
                                <v-list-item-subtitle class="primary--text" v-else>{{ configuracao.valor | valorBR(0) }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item :key="configuracao.id" v-if="configuracao.tipo == 'boolean'" @click.stop="fazerNada">
                              <v-list-item-action v-show="podeapagar">
                                <v-btn icon @click.stop="fazerNada">
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </v-list-item-action>
                              <v-list-item-action v-show="dev">
                                <v-btn icon @click.stop="copiarTexto(configuracao.config)">
                                  <v-icon>mdi-content-copy</v-icon>
                                </v-btn>
                              </v-list-item-action>

                              <v-list-item-content @click="configuracao.valor = !configuracao.valor; atualizar(configuracao)">
                                <v-list-item-title v-if="dev" :id="configuracao.config" style="user-select: auto">{{ configuracao.config }}</v-list-item-title>
                                <v-list-item-title v-else>{{ configuracao.descricao }}</v-list-item-title>
                                <v-list-item-subtitle>{{ configuracao.ajuda }}</v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-switch color="primary" single-line hide-details v-model="configuracao.valor" class="shrink" @change="atualizar(configuracao)" :true-value="true" :false-value="false"></v-switch>
                              </v-list-item-action>
                            </v-list-item>

                            <v-divider :inset="dev" v-if="index < configuracoes.length - 1" :key="configuracao.descricao"></v-divider>

                          </template>
                        </v-list>
                      </v-card-text>
                  </v-card>
              </v-col>
          </v-row>


        </z-container>

      <z-dialogo ref="dialogoEditar" :titulo="configuracao.descricao" :dividers="false" largura="400px">
        <template v-slot:texto>
          <z-text-field ref="valor" v-model="configuracao.valor" single-line autofocus v-if="configuracao.tipo == 'string'"></z-text-field>
          <z-campo-valor ref="valor" v-model="configuracao.valor" single-line autofocus v-if="configuracao.tipo == 'float' || configuracao.tipo == 'integer'" :decimais="configuracao.tipo == 'float' ? 2 : 0"></z-campo-valor>
        </template>

        <template v-slot:actions_right>
          <v-btn text @click="$refs.dialogoEditar.fechar()" :disabled="salvando">Cancelar</v-btn>
          <v-btn color="primary" text @click="atualizar(configuracao)" :loading="salvando" :disabled="salvando">Salvar</v-btn>
        </template>

      </z-dialogo>

      <z-dialogo ref="dialogoAdicionarConfiguracao" titulo="Nova Configuração" :dividers="true" largura="500px">
        <template v-slot:texto>
          <v-row row wrap>
            <v-col cols="12">
              <z-text-field label="Descrição" placeholder=" " v-model="config.descricao"></z-text-field>
            </v-col>
            <v-col cols="12">
              <z-text-field label="Config" placeholder=" " v-model="config.config"></z-text-field>
            </v-col>
            <v-col cols="12">
              <z-select label="Tipo" :items="['string', 'float', 'integer', 'boolean']" v-model="config.tipo"></z-select>
            </v-col>
            <v-col cols="12">
              <z-textarea label="Ajuda" placeholder="Ajuda sobre a configuração" v-model="config.ajuda" hide-details></z-textarea>
            </v-col>
          </v-row>
        </template>
        <template v-slot:actions_right>
          <v-btn text @click="$refs.dialogoAdicionarConfiguracao.fechar()" :disabled="salvando">Cancelar</v-btn>
          <v-btn color="primary" text @click="salvar" :loading="salvando" :disabled="salvando">Salvar</v-btn>
        </template>
      </z-dialogo>
    </v-app>
</template>

<script>

  import { copyText } from '@/app/helpers/utils';

    export default {
        name: "configuracoes",
        data() {
          return {
            busca: '',
            loading: false,
            salvando: false,
            podeapagar: false,
            configuracao: {
              descricao: '',
              valor: ''
            },
            config: {
              descricao: '',
              config: '',
              tipo: 'string',
              ajuda: ''
            },
            dev: false
          };
        },

        mounted() {
          this.$store.commit("setTitulo", "Configurações");
          this.$store.dispatch("carregarConfiguracoes");
          this.$store.dispatch("carregarListaConfiguracoes");
        },

        methods: {
          editar(configuracao) {
            this.configuracao = this.clone(configuracao);
            this.$refs.dialogoEditar.abrir();
            // this.setFocus('valor');
          },

          async atualizar(configuracao) {
            try {
              
              this.salvando = true;
              await this.$store.dispatch("atualizar", configuracao);
              this.configuracoes.find(config => config.id == configuracao.id).valor = configuracao.valor;
              this.$eventbus.notificar('Configuração salva.')

            } catch (error) {
              this.$eventbus.notificarErro('Não foi possível salvar configuração.', error);
            } finally {
              this.$refs.dialogoEditar.fechar();
              this.salvando = false;
            }
          },

          async salvar() {
            try {
              this.salvando = true;
              await this.$store.dispatch("salvar", this.config);
              this.$refs.dialogoAdicionarConfiguracao.fechar();
              this.$eventbus.notificar('Configuração salva.');
              this.config = {
                descricao: '',
                config: '',
                tipo: 'string',
                ajuda: ''
              }
              this.refresh();

            } catch (error) {
              this.$eventbus.notificarErro('Não foi possível salvar configuração.', error);
            } finally {
              this.$refs.dialogoEditar.fechar();
              this.salvando = false;
            }
          },

          async refresh() {
            this.loading = true;
            await this.$store.dispatch("carregarListaConfiguracoes");
            this.loading = false;
          },

          adicionar() {
            this.$refs.dialogoAdicionarConfiguracao.abrir();
          },

          fazerNada() {
            //
          },

          copiarTexto(id) {
            copyText(id);
          }
        },

        computed: {
          configuracoes() {
            let configuracoes = this.$store.state.lista_configuracoes;
            
            return configuracoes.filter(configuracao => {
              let busca1 = true, busca2 = true, busca3 = true;
              if (this.busca) {
                busca1 = configuracao.descricao.toUpperCase().indexOf(this.busca.toUpperCase()) > -1;
                busca2 = configuracao.config.toUpperCase().indexOf(this.busca.toUpperCase()) > -1;
                busca3 = configuracao.ajuda.toUpperCase().indexOf(this.busca.toUpperCase()) > -1;
              }
              return busca1 || busca2 || busca3;
            });
          }
        }
    };

</script>

<style scoped>
</style>
