<template>
    <div>
      <z-centralized-toolbar>
        <v-toolbar-items>
          <v-btn id="btn-adicionar" text small color="primary" @click="adicionar">
            <v-icon left>{{ CLASSIFICACOES_ICON }}</v-icon
            >Adicionar
          </v-btn>
  
          <v-btn text id="btn-atualizar" @click="refresh" :loading="$store.state.loading">
            <v-icon>mdi-refresh</v-icon>
            <v-tooltip bottom activator="#btn-atualizar">
              Atualizar
            </v-tooltip>
          </v-btn>
        </v-toolbar-items>
  
        <!-- <v-spacer></v-spacer> -->
  
        <z-text-field-search v-model="busca" select class="ml-5" @keydown.enter="buscar" @click:clear="limparBusca"></z-text-field-search>
      </z-centralized-toolbar>
  
      <v-divider></v-divider>
  
      <z-scroll-layout :top-position="$v.TOP_POSITION">
        <z-container>
          <z-cabecalho>{{ $store.state.titulo_tela }}</z-cabecalho>
  
          <v-card>
            <v-data-table mobile-breakpoint="0" :options.sync="paginacao" :server-items-length="total_itens" :footer-props="$v.FOOTER_PROPS" :headers="headers" :items="classificacoes" item-key="id">
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.id }}</td>
                  <td>
                    {{ item.descricao }}
                    <v-chip
                      v-if="item.investimento"
                      small
                      color="teal lighten-4"
                      text-color="teal darken-2"
                    >Investimento</v-chip>
                  </td>
                  <td class="text-center">
                    <v-chip small label text-color="white" :color="item.tipo == 'Receita' ? 'green' : 'red'">{{ item.tipo }}</v-chip>
                  </td>
                  <td class="text-center text-no-wrap">
                    <v-btn icon @click="editar(item)" class="ma-0">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon @click="perguntarExcluir(item)" class="ma-0">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </z-container>
      </z-scroll-layout>
  
      <z-dialogo-sim-nao ref="dialogoRemover" titulo="Remover classificacao?" botaoSim="Remover" botaoNao="Cancelar" :loading="excluindo">
        O classificacao será removido do sistema.
      </z-dialogo-sim-nao>
  
      <v-form v-model="form_valido" lazy-validation ref="formulario">
        <z-dialogo ref="dialogoAdicionar" largura="800px" titulo="Classificação" dividers persistent @abrir="$refs.formulario.resetValidation()">
          <template v-slot:conteudo>
            <v-row dense>
              <v-col cols="12" sm="9">
                <z-text-field upper v-model="classificacao.descricao" ref="descricao" label="Descrição" persistent-placeholder :rules="[regras.campoObrigatorio]"></z-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <z-select v-model="classificacao.tipo" ref="tipo" label="Tipo" :items="['Receita', 'Despesa']" persistent-placeholder :disabled="!!classificacao.id"></z-select>
              </v-col>
              <v-col cols="12" sm="3">
                <v-switch 
                  v-model="classificacao.investimento"
                  class="my-0"
                  label="Investimento"
                  hide-details
                  single-line
                ></v-switch>
              </v-col>
            </v-row>
          </template>
  
          <template v-slot:actions_right>
            <v-btn text @click="$refs.dialogoAdicionar.fechar()" :disabled="salvando">Cancelar</v-btn>
            <v-btn color="primary" ref="btnConfirmar" @click="confirmar" :loading="salvando" :disabled="!form_valido">Confirmar</v-btn>
          </template>
        </z-dialogo>
      </v-form>
  
      <z-dialogo-pergunta ref="dialogo_pergunta"></z-dialogo-pergunta>
    </div>
  </template>
  
  <script>
  import { mapMutations, mapActions, mapState } from 'vuex';
  import * as regras from '@/app/helpers/validacoes';
  import { CLASSIFICACOES } from '@/app/icons';
  
  export default {
    name: 'ClassificacoesLista',
    data() {
      return {
        headers: [
          { text: 'Código', value: 'id', width: '100px' },
          { text: 'Descrição', value: 'descricao' },
          { text: 'Tipo', value: 'tipo', align: 'center' },
          { text: 'Opções', value: '', width: '100px', align: 'center', sortable: false },
        ],
        excluindo: false,
        salvando: false,
        form_valido: true,
        regras: { ...regras },
        CLASSIFICACOES_ICON: CLASSIFICACOES,
      };
    },
  
    computed: {
      ...mapState(['loading']),
      ...mapState('classificacoes', ['classificacoes', 'classificacao', 'total_itens']),
  
      busca: {
        get() {
          return this.$store.state.classificacoes.busca;
        },
        set(busca) {
          this.$store.commit('classificacoes/SET_BUSCA', busca);
        },
      },
  
      paginacao: {
        get() {
          return this.$store.state.classificacoes.paginacao;
        },
        set(paginacao) {
          this.$store.commit('classificacoes/UPDATE_PAGINACAO', paginacao);
        },
      },
    },
  
    created() {
      this.refresh();
    },
  
    mounted() {
      this.setTitulo('Classificações/DRE');
    },
  
    methods: {
      ...mapMutations(['setTitulo', 'LOADING_OFF']),
      ...mapActions('classificacoes', ['listar', 'excluir', 'abrir', 'atualizar', 'salvar']),
  
      async refresh() {
        try {
          await this.listar();
        } catch (error) {
          this.$eventbus.notificar('Não foi possível listar as classificações.');
        } finally {
          this.$store.commit('LOADING_OFF', null);
        }
      },
  
      buscar() {
        this.$store.commit('classificacoes/SET_PAGE', 1);
        this.refresh();
      },
  
      limparBusca() {
        this.busca = null;
        this.$store.commit('classificacoes/SET_PAGE', 1);
        this.refresh();
      },
  
      adicionar() {
        this.$store.commit('classificacoes/ZERAR');
        this.$refs.dialogoAdicionar.abrir();
      },
  
      async editar(classificacao) {
        try {
          await this.abrir(classificacao.id);
          this.$refs.dialogoAdicionar.abrir();
        } catch (error) {
          console.log(error);
          this.$eventbus.notificar('Não foi possível abrir a classificação.');
        } finally {
          this.LOADING_OFF();
        }
      },
  
      async confirmar() {
        if (!this.$refs.formulario.validate()) {
          return false;
        }
  
        try {
          this.salvando = true;
  
          if (this.classificacao.id) {
            await this.atualizar(this.classificacao);
          } else {
            await this.salvar(this.classificacao);
          }
  
          this.$refs.dialogoAdicionar.fechar();
          this.$eventbus.notificar('Classificação salva com sucesso');
        } catch (error) {
          this.$eventbus.notificarErro('Não foi possível salvar esta classificação. Verifique os dados preenchidos.', error);
          throw error;
        } finally {
          this.salvando = false;
        }
      },
  
      async perguntarExcluir(classificacao) {
        let resposta = await this.$refs.dialogo_pergunta.abrir({
          titulo: 'Remover Classificação',
          texto: 'A classificação será removida do sistema.',
          botoes: ['Cancelar', 'Remover'],
          padrao: 'Remover',
          cor: 'red',
        });
  
        if (resposta == 'Remover') {
          this.$refs.dialogo_pergunta.carregando();
          await this.confirmarExcluir(classificacao);
          this.$refs.dialogo_pergunta.fechar();
        } else {
          this.$refs.dialogo_pergunta.fechar();
        }
      },
  
      async confirmarExcluir(classificacao) {
        try {
          this.excluindo = true;
          await this.excluir(classificacao.id);
          this.$eventbus.notificar('Classificação excluída com sucesso!');
        } catch (error) {
          this.$eventbus.notificar('Não foi possível excluir o classificação!');
          throw error;
        } finally {
          this.excluindo = false;
        }
      },
    },
  
    watch: {
      paginacao: {
        handler() {
          this.refresh();
        },
        deep: true,
      },
    },
  };
  </script>
  
  <style scoped></style>
  