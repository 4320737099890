import contasApi from "@/app/services/contasApi";

export default {
  namespaced: true,
  state: {
    conta: {
      id: "",
      descricao: "",
    },
    contas: [],
    busca: "",
    total_itens: 0,
    paginacao: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ["id"],
      sortDesc: [true],
    },
  },

  mutations: {
    LISTAR(state, contas) {
      state.contas = contas;
    },

    ABRIR(state, conta) {
      state.conta = conta;
    },

    ADICIONAR(state, conta) {
      state.contas.unshift(conta);
    },

    ATUALIZAR(state, conta) {
      let index = state.contas.findIndex((conta_) => conta_.id == conta.id);
      if (index > -1) {
        state.contas.splice(index, 1, conta);
      }
    },

    EXCLUIR(state, id) {
      let index = state.contas.findIndex((conta) => conta.id == id);
      if (index > -1) {
        state.contas.splice(index, 1);
      }
    },

    SET_BUSCA(state, busca) {
      state.busca = busca;
    },

    SET_PAGE(state, page) {
      state.paginacao.page = page;
    },

    SET_TOTAL_ITENS(state, total_itens) {
      state.total_itens = total_itens;
    },

    UPDATE_PAGINACAO(state, paginacao) {
      state.paginacao = paginacao;
    },

    ZERAR(state) {
      state.conta = {
        id: "",
        descricao: "",
      };
    },
  },

  actions: {
    async listar({ commit, state }) {
      commit("LOADING_ON", null, { root: true });

      const { sortBy, sortDesc, page, itemsPerPage } = state.paginacao;

      const paginacao = {
        page,
        itemsPerPage,
        sortBy: sortBy[0],
        sortDesc: sortDesc[0],
      };

      if (state.busca) {
        paginacao.busca = state.busca;
      }

      let response = await contasApi.listar(paginacao);
      commit("LISTAR", response.data);
      commit("SET_TOTAL_ITENS", response.total);
      commit("LOADING_OFF", null, { root: true });
    },

    async abrir({ commit }, id) {
      commit("LOADING_ON", null, { root: true });
      let conta = await contasApi.abrir(id);
      commit("ABRIR", conta);
      commit("LOADING_OFF", null, { root: true });
    },

    async salvar({ commit }, conta) {
      let novo = await contasApi.salvar(conta);
      commit("ADICIONAR", novo);
    },

    async atualizar({ commit }, conta) {
      await contasApi.atualizar(conta);
      commit("ATUALIZAR", conta);
    },

    async excluir({ commit }, id) {
      await contasApi.excluir(id);
      commit("EXCLUIR", id);
    },
  },
};
