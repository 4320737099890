<template>
  <v-toolbar flat v-bind="$attrs">
    <v-container :fluid="fluid" py-0>
      <v-row dense justify="center">
        <v-col cols="12" :xl="xl" class="v-toolbar__content v-toolbar__buttons" style="height: 64px;">
          <slot></slot>
        </v-col>
      </v-row>
    </v-container>

    <v-progress-linear
        :active="$store.state.loading"
        :indeterminate="true"
        absolute
        bottom
        color="primary"
        height="1"
      ></v-progress-linear>
  </v-toolbar>
</template>

<script>
  export default {
    name: 'z-centralized-toolbar',
    
    props: {
      fluid: {
        type: Boolean,
        default: false
      },

      xl: {
        type: [String, Number],
        default: '9'
      }
    },
  }
</script>

<style scoped>
.v-toolbar__buttons button:not(:last-child),
.v-toolbar__buttons a:not(:last-child) {
    margin-right: 10px !important;
}
</style>