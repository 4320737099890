<template>
  <v-dialog v-model="exibir" persistent max-width="400px" :return-value="resposta" eager>

    <v-form ref="form" v-model="form_valido" lazy-validation autocomplete="off">
      <v-card>
        <v-card-title v-show="titulo">{{ titulo }}</v-card-title>

        <v-card-text class="text--primary mb-4">
          <v-row>
            <v-col>
              {{ texto }}
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="text--primary pa-5">

          <v-row dense>
            <v-col cols="12">
              <z-select
                v-model="usuario.user_id"
                label="Usuário Administrador"
                hint="Selecione um usuário administrador"
                persistent-hint
                :items="admins"
                :rules="regras.user_id"
              ></z-select>
            </v-col>
            <v-col cols="12">
              <z-text-field
                v-model="usuario.password"
                autocomplete="off"
                ref="senha"
                color="primary"
                label="Senha"
                placeholder=" "
                prepend-inner-icon="mdi-lock"
                required
                type="password"
                :rules="regras.password"
                :error="errors.password.length > 0"
                :error-messages="errors.password"
                @focus="errors.password = ''"
              ></z-text-field>
            </v-col>
          </v-row>


        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text :disabled="loading" @click="fechar">Cancelar</v-btn>
          <v-btn depressed color="red" class="white--text" :disabled="loading || !form_valido" :loading="loading" @click="autorizar">Autorizar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

  </v-dialog>
</template>

<script>

import usuariosApi from "@/app/services/usuariosApi";

export default {
  name: "zAutorizacaoAdmin",

  data() {
    return {
      form_valido: true,

      usuario: {
        user_id: '',
        password: ''
      },

      regras: {
        user_id: [(v) => !!v || 'Informar o usuário'],
        password: [(v) => !!v || 'Informar a senha do usuário'],
      },

      errors: {
        password: ''
      },

      exibir: false,
      loading: false,
      resposta: false,
      resolve: null,
      reject: null,

      titulo: '',
      texto: '',
    };
  },

  computed: {
    admins() {
      return this.$store.getters['listas/admins'];
    }
  },

  methods: {
    async abrir(opcoes) {
      this.$refs.form.resetValidation();

      this.usuario = {
        user_id: '',
        password: ''
      }

      await this.$store.dispatch('listas/admins');

      this.titulo = opcoes.titulo || "Título";
      this.texto = opcoes.texto || "Texto";

      this.exibir = true;


      this.$emit("abrir");
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    fechar() {
      this.exibir = false;
      this.loading = false;
      this.$emit("fechar");
      this.resolve(false);
    },

    responder(resposta) {
      // this.fechar();
      this.resolve(resposta);
    },

    carregando() {
      this.loading = true;
    },

    async autorizar() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.errors.password = '';
      this.loading = true;
      let response = await usuariosApi.autorizar(this.usuario);

      if (response.autorizado) {
        this.loading = false;
        this.exibir = false;
        this.resolve(true);
        this.$emit("fechar");
      } else {
        this.errors.password = 'Senha inválida';
        this.loading = false;
      }
    },

  },
};
</script>

<style scoped></style>
