import createAxios from "@/app/core/AxiosCore";
import config from "@/app/config/app";

const axios = createAxios(config);

function listar(filtro) {
  filtro = new URLSearchParams(filtro).toString();
  return axios.get("caixa?" + filtro).then((response) => response.data);
}

function abrir(id) {
  return axios.get("/caixa/" + id).then((response) => response.data);
}

function salvar(caixa) {
  return axios.post("/caixa", caixa).then((response) => response.data);
}

function salvarSangria(caixa) {
  return axios.post("/caixa/sangria", caixa).then((response) => response.data);
}

function atualizar(caixa) {
  return axios.put("/caixa/" + caixa.id, caixa).then((response) => response.data);
}

function excluir(id) {
  return axios.delete("/caixa/" + id).then((response) => response.data);
}

export default {
  listar,
  abrir,
  salvar,
  salvarSangria,
  atualizar,
  excluir,
};
