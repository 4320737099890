import * as icon from '@/app/icons';

export default [
  {
    id: 'todas',
    name: 'Acesso Total',
    icon: 'mdi-check-all',
    children: [
      {
        id: 'cadastros',
        name: 'Cadastros',
        icon: icon.CADASTROS,
        visible: true,
        dense: true,
        children: [
          {
            id: 'contas',
            name: 'Contas',
            icon: icon.BANCOS,
            to: { name: 'ContasLista' },
            permissao: 'contas_acessar',
            disabled: false,
            children: [
              { id: 'contas_acessar', name: 'Acessar', icon: icon.ACESSAR },
              { id: 'contas_adicionar', name: 'Adicionar', icon: icon.ADICIONAR },
              { id: 'contas_editar', name: 'Editar', icon: icon.EDITAR },
              { id: 'contas_excluir', name: 'Excluir', icon: icon.EXCLUIR },
            ],
          },
          {
            id: 'classificacoes',
            name: 'Classificações',
            icon: icon.CLASSIFICACOES,
            to: { name: 'ClassificacoesLista' },
            permissao: 'classificacoes_acessar',
            disabled: false,
            children: [
              { id: 'classificacoes_acessar', name: 'Acessar', icon: icon.ACESSAR },
              { id: 'classificacoes_adicionar', name: 'Adicionar', icon: icon.ADICIONAR },
              { id: 'classificacoes_editar', name: 'Editar', icon: icon.EDITAR },
              { id: 'classificacoes_excluir', name: 'Excluir', icon: icon.EXCLUIR },
            ],
          },
          {
            id: 'sedes',
            name: 'Sedes',
            icon: icon.SEDES,
            to: { name: 'SedesLista' },
            permissao: 'sedes_acessar',
            disabled: false,
            children: [
              { id: 'sedes_acessar', name: 'Acessar', icon: icon.ACESSAR },
              { id: 'sedes_adicionar', name: 'Adicionar', icon: icon.ADICIONAR },
              { id: 'sedes_editar', name: 'Editar', icon: icon.EDITAR },
              { id: 'sedes_excluir', name: 'Excluir', icon: icon.EXCLUIR },
            ],
          },
        ],
      },
      {
        id: 'movimentacoes',
        name: 'Movimentações',
        icon: icon.MOVIMENTACOES,
        visible: true,
        dense: true,
        children: [
          {
            id: 'caixa',
            name: 'Caixa',
            icon: icon.ORDENS_SERVICO,
            to: { name: 'CaixaLista' },
            permissao: 'caixa_acessar',
            disabled: false,
            children: [
              { id: 'caixa_acessar', name: 'Acessar', icon: icon.ACESSAR },
              { id: 'caixa_adicionar', name: 'Adicionar', icon: icon.ADICIONAR },
              { id: 'caixa_editar', name: 'Editar', icon: icon.EDITAR },
              { id: 'caixa_excluir', name: 'Excluir', icon: icon.EXCLUIR },
            ],
          },
        ],
      },
      // {
      //   id: "relatorios",
      //   name: "Relatórios",
      //   icon: icon.CONSULTAS,
      //   visible: true,
      //   children: [
      //     {
      //       id: "comissoes_servicos_acessar",
      //       name: "Comissões Serviços",
      //       icon: icon.CONSULTAS,
      //       to: { name: "ComissoesServicos" },
      //       permissao: "comissoes_servicos_acessar",
      //       disabled: false,
      //     },
      //     {
      //       id: "comissoes_servicos_consolidada_acessar",
      //       name: "Comissões Serviços Consolidada",
      //       icon: icon.CONSULTAS,
      //       to: { name: "ComissoesServicosConsolidada" },
      //       permissao: "comissoes_servicos_consolidada_acessar",
      //       disabled: false,
      //     },
      //   ],
      // },
      {
        id: 'sistema',
        name: 'Sistema',
        icon: icon.SISTEMA,
        visible: true,
        dense: true,
        children: [
          {
            id: 'usuarios',
            name: 'Usuários',
            descricao: 'Cadastro de usuários do sistema',
            icon: icon.USUARIOS,
            to: { name: 'UsuariosLista' },
            permissao: 'usuarios_acessar',
            disabled: false,
            children: [
              { id: 'usuarios_acessar', name: 'Acessar', icon: icon.ACESSAR },
              { id: 'usuarios_adicionar', name: 'Adicionar', icon: icon.ADICIONAR },
              { id: 'usuarios_editar', name: 'Editar', icon: icon.EDITAR },
              { id: 'usuarios_excluir', name: 'Excluir', icon: icon.EXCLUIR },
            ],
          },
          {
            id: 'permissoes_acessar',
            name: 'Permissões',
            descricao: 'Permissões dos usuários',
            icon: icon.PERMISSOES,
            to: { name: 'Permissoes' },
            permissao: 'permissoes_acessar',
            disabled: false,
          },
          // { id: 'configuracoes_acessar', name: 'Configurações' },
        ],
      },
    ],
  },
];
