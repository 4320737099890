import createAxios from "@/app/core/AxiosCore";
import config from "@/app/config/app";

const axios = createAxios(config);

function listar(paginacao) {
  return axios.get(`contas`, { params: paginacao }).then((response) => response.data);
}

function buscar(busca) {
  return axios.get(`contas/buscar?busca=${busca}`).then((response) => response.data);
}

function buscarPorCodigo(codigo) {
  return axios.get(`contas/buscarCodigo?codigo=${codigo}`).then((response) => response.data);
}

function abrir(id) {
  return axios.get(`/contas/${id}`).then((response) => response.data);
}

function salvar(conta) {
  return axios.post("/contas", conta).then((response) => response.data);
}

function atualizar(conta) {
  return axios.put(`/contas/${conta.id}`, conta).then((response) => response.data);
}

function excluir(id) {
  return axios.delete(`/contas/${id}`).then((response) => response.data);
}

export default {
  listar,
  buscar,
  buscarPorCodigo,
  abrir,
  salvar,
  atualizar,
  excluir,
};
