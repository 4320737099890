import SedesLista from "@/pages/cadastros/sedes/SedesLista";

export default [
  {
    path: "/cadastros/sedes",
    name: "SedesLista",
    component: SedesLista,
    meta: { permissao: "sedes_acessar" },
  },
];
