
<template>
  <v-text-field v-model="hora" v-bind="attributes" v-mask="'##:##'">
    <template v-slot:append>
      <v-icon class="mr-2" @click="exibir = true">mdi-alarm</v-icon>
      <v-dialog ref="dialog" v-model="exibir" persistent width="290px">
        <v-time-picker v-if="exibir" v-model="hora" format="24hr" full-width>
          <v-btn text icon @click="setAgora">
            <v-icon>mdi-alarm</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="exibir = false">Cancelar</v-btn>
          <v-btn text color="primary" @click="$refs.dialog.save(hora)">OK</v-btn>
        </v-time-picker>
      </v-dialog>
    </template>
  </v-text-field>
</template>

<script>
import form_mixin from "./form_mixin";

export default {
    name: 'z-time-picker',

    mixins: [form_mixin],
    
    props: {
      value: {
        type: String,
        default: null
      },
      transparente: {
        type: Boolean,
        default: false
      },
      raiseOnFocus: Boolean,
      flat: Boolean,
      readonly: Boolean,
      disabled: Boolean,
      min: String
    },

    data() {
      return {
        dateFormatted: null,
        menu: false,
        isFocused: false,
        exibir: false,
      }
    },

    computed: {
      attributes() {
        return { ...this.$attrs, ...this.$v.TEXT_FIELDS };
      },
      
      hora: {
        get () {
          return this.value;
        },
        set (hora) {
          this.$emit('input', hora);
        }
      },

      isToBeFlat() {
        if (this.flat && this.raiseOnFocus && this.isFocused) {
          return false;
        } else {
          return this.flat;
        }
      },

      isSm() {
        return this.$vuetify.breakpoint.smAndDown;
      },
    },

    methods: {
      setAgora() {
        console.log(this.horaAgora());
        this.hora = this.horaAgora();
      },

      horaAgora() {
        let data = new Date();
        return data.getHours().toString().padStart(2,'0') + ':' + data.getMinutes();
      },
    },

}

</script>

<style scoped>

</style>
