import { render, staticRenderFns } from "./AppUserMenu.vue?vue&type=template&id=39ed5ddf&scoped=true&"
import script from "./AppUserMenu.vue?vue&type=script&lang=js&"
export * from "./AppUserMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39ed5ddf",
  null
  
)

export default component.exports