import classificacoesApi from '@/app/services/classificacoesApi';

export default {
  namespaced: true,
  state: {
    classificacao: {
      id: null,
      descricao: '',
      tipo: 'Receita',
    },
    classificacoes: [],
    busca: '',
    total_itens: 0,
    paginacao: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['id'],
      sortDesc: [true],
    },
  },

  mutations: {
    LISTAR(state, classificacoes) {
      state.classificacoes = classificacoes;
    },

    ABRIR(state, classificacao) {
      state.classificacao = classificacao;
    },

    ADICIONAR(state, classificacao) {
      state.classificacoes.unshift(classificacao);
    },

    ATUALIZAR(state, classificacao) {
      let index = state.classificacoes.findIndex((classificacao_) => classificacao_.id == classificacao.id);
      if (index > -1) {
        state.classificacoes.splice(index, 1, classificacao);
      }
    },

    EXCLUIR(state, id) {
      let index = state.classificacoes.findIndex((classificacao) => classificacao.id == id);
      if (index > -1) {
        state.classificacoes.splice(index, 1);
      }
    },

    SET_BUSCA(state, busca) {
      state.busca = busca;
    },

    SET_PAGE(state, page) {
      state.paginacao.page = page;
    },

    SET_TOTAL_ITENS(state, total_itens) {
      state.total_itens = total_itens;
    },

    UPDATE_PAGINACAO(state, paginacao) {
      state.paginacao = paginacao;
    },

    ZERAR(state) {
      state.classificacao = {
        id: null,
        descricao: '',
        tipo: 'Receita',
      };
    },
  },

  actions: {
    async listar({ commit, state }) {
      commit('LOADING_ON', null, { root: true });

      const { sortBy, sortDesc, page, itemsPerPage } = state.paginacao;

      const paginacao = {
        page,
        itemsPerPage,
        sortBy: sortBy[0],
        sortDesc: sortDesc[0],
      };

      if (state.busca) {
        paginacao.busca = state.busca;
      }

      let response = await classificacoesApi.listar(paginacao);
      commit('LISTAR', response.data);
      commit('SET_TOTAL_ITENS', response.total);
      commit('LOADING_OFF', null, { root: true });
    },

    async abrir({ commit }, id) {
      commit('LOADING_ON', null, { root: true });
      let classificacao = await classificacoesApi.abrir(id);
      commit('ABRIR', classificacao);
      commit('LOADING_OFF', null, { root: true });
    },

    async salvar({ commit }, classificacao) {
      let novo = await classificacoesApi.salvar(classificacao);
      commit('ADICIONAR', novo);
    },

    async atualizar({ commit }, classificacao) {
      await classificacoesApi.atualizar(classificacao);
      commit('ATUALIZAR', classificacao);
    },

    async excluir({ commit }, id) {
      await classificacoesApi.excluir(id);
      commit('EXCLUIR', id);
    },
  },
};
