<template>
  <v-textarea ref="input" v-bind="attributes" v-on="$listeners"></v-textarea>
</template>

<script>
import form_mixin from "./form_mixin";

export default {
  name: "z-textarea",

  mixins: [form_mixin],

  computed: {
    attributes() {
      return { ...this.$attrs, ...this.$v.TEXT_FIELDS };
    },
  },
};
</script>

<style scoped>
</style>