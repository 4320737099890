<template>
  <v-list nav dense>

    <v-subheader class="text-uppercase text--primary font-weight-black">Menu</v-subheader>

    <v-list-item to="/" color="primary">
      <v-list-item-icon>
        <v-icon>mdi-home</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Home</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <template v-for="(menu, index) in permissoes">
      <!-- <v-subheader class="text-uppercase text--primary font-weight-black" :key="`a${index}`">{{menu.name}}</v-subheader> -->

      <v-list-group :group="menu.id" color="primary" :key="`b${index}`" v-if="menu.visible" no-action>

        <v-divider v-if="menu.name=='divider'" :key="i+'a'"></v-divider>

        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon>{{menu.icon}}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="menu.name" class_="text-uppercase text--primary font-weight-black"></v-list-item-title>
          </v-list-item-content>
        </template>

        <template v-for="(item, i) in menu.children">
          <v-divider v-if="item.text=='divider'" :key="i+'b'"></v-divider>
          <v-list-item v-else :value="item.name" :key="i" :to="item.to" :disabled="!$auth.can(item.permissao)">
            <!-- <v-list-item-icon v-if="item.icon">
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon> -->

            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-divider v-if="index < permissoes.length - 1"></v-divider>

      </v-list-group>

    </template>

  </v-list>
</template>

<script>
  import menu from "./menu";
  import permissoes from "@/pages/sistema/usuarios/permissoes";

  export default {
    props: {
      miniVariant: {
        type: Boolean
      }
    },
    data() {
      return {
        menus: menu,
        item: ''
      }
    },
    
    computed: {
      permissoes() {
        return permissoes[0].children; 
      }
    },

    methods: {
      sair() {
        setTimeout(() => {
          this.$auth.logout();
          this.$router.push('/login');
        }, 1000);
      }
    },
  }
</script>

<style scoped>

</style>
