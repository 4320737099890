<template>
  <v-navigation-drawer disable-resize-watcher v-bind="$attrs" app fixed :clipped="clipped" :mini-variant.sync="miniVariant" :value="drawer" @input="$emit('update:drawer', $event)">
    <template v-slot:prepend>
      <v-toolbar dark color="primary">
        <v-btn icon @click="goHome">
          <v-icon>mdi-car</v-icon>
        </v-btn>

        <v-toolbar-title>
          <span class="mr-2 apontador" @click="goHome">{{ config.APP_NAME }}</span>
          <!-- <span class="text-caption mr-2">{{ config.APP_VERSION }}</span> -->
        </v-toolbar-title>
      </v-toolbar>
    </template>

    <app-menu :mini-variant="miniVariant"></app-menu>

    <template v-slot:append>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="12" class="text-center text-subtitle-2">
          <span>{{ config.APP_VERSION }}</span>
        </v-col>
      </v-row>
    </template>
  </v-navigation-drawer>
</template>

<script>
import AppMenu from "./menus/AppMenu";
import config from "@/app/config/app";

export default {
  components: {
    AppMenu,
  },

  props: {
    drawer: {
      type: Boolean,
      default: true,
    },
    permanent: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      clipped: false,
      fixed: true,
      miniVariant: false,
      dataehora: "",
      config: config,
    };
  },

  mounted() {
    setInterval(() => {
      let d = new Date();
      // let hora = new Date().toLocaleTimeString('pt-BR', {hour: "numeric", minute: "numeric", second: "numeric"});
      this.dataehora = d.toLocaleDateString("pt-BR") + " " + d.getHours() + ":" + d.getMinutes();
    }, 1000);
  },

  methods: {
    goHome() {
      this.$router.push({ name: "Home" }).catch((error) => {});
    },
  },
};
</script>

<style scoped></style>
