import listasApi from "@/app/services/listasApi";

export default {
  namespaced: true,
  state: {
    sedes: [],
    classificacoes: [],
    contas: [],
    perfis: [],
    admins: [],
  },

  getters: {
    sedes(state) {
      return state.sedes.map((sede) => {
        return {
          text: sede.descricao,
          value: sede.id,
        };
      });
    },

    classificacoes(state) {
      return state.classificacoes.map((classificacao) => {
        return {
          text: `${classificacao.descricao} (${classificacao.tipo})`,
          value: classificacao.id,
        };
      });
    },

    contas(state) {
      return state.contas.map((conta) => {
        return {
          text: conta.descricao,
          value: conta.id,
        };
      });
    },

    perfis(state) {
      return state.perfis.map((perfil) => {
        return {
          text: perfil.descricao,
          value: perfil.id,
        };
      });
    },

    admins(state) {
      return state.admins.map((admin) => {
        return {
          text: admin.name,
          value: admin.id,
        };
      });
    },
  },

  mutations: {
    SET_SEDES(state, sedes) {
      state.sedes = sedes;
    },

    SET_CLASSIFICACOES(state, classificacoes) {
      state.classificacoes = classificacoes;
    },

    SET_CONTAS(state, contas) {
      state.contas = contas;
    },

    SET_PERFIS(state, perfis) {
      state.perfis = perfis;
    },

    SET_ADMINS(state, admins) {
      state.admins = admins;
    },
  },

  actions: {
    async sedes({ commit }) {
      let sedes = await listasApi.sedes();
      commit("SET_SEDES", sedes);
    },

    async classificacoes({ commit }) {
      let classificacoes = await listasApi.classificacoes();
      commit("SET_CLASSIFICACOES", classificacoes);
    },

    async contas({ commit }) {
      let contas = await listasApi.contas();
      commit("SET_CONTAS", contas);
    },

    async perfis({ commit }) {
      let perfis = await listasApi.perfis();
      commit("SET_PERFIS", perfis);
    },
    
    async admins({ commit }) {
      let admins = await listasApi.admins();
      commit("SET_ADMINS", admins);
    },
  },
};
