<template>
  <v-text-field
    v-bind="attributes"
    :value="value"
    ref="input"
    @input.native="onInput"
  ></v-text-field>
</template>

<script>
export default {
  name: "z-text-field",

  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    caps: {
      type: String,
      default: "normal",
    },
  },

  computed: {
    attributes() {
      return { ...this.$attrs, ...this.$v.TEXT_FIELDS };
    },
  },

  methods: {
    onInput(event) {
      let posicao = event.target.selectionStart; // Marca a posição do cursor
      event.target.value = this.transforma(event.target.value);
      event.target.setSelectionRange(posicao, posicao); // Restaura a posição do cursor

      this.$emit("input", this.transforma(event.target.value));
    },

    transforma(texto = "") {
      if (this.caps == "upper") {
        return texto.toUpperCase();
      }

      if (this.caps == "lower") {
        return texto.toLowerCase();
      }

      return texto;
    },

    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>

<style scoped>
</style>