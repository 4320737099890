export const ACESSAR = 'mdi-lock-open';
export const ADICIONAR = 'mdi-plus';
export const EDITAR = 'mdi-pencil';
export const EXCLUIR = 'mdi-delete';
export const IMPRIMIR = 'mdi-printer';
export const FINALIZAR = 'mdi-arrow-down';
export const SALVAR = 'mdi-content-save';
export const CANCELAR = 'mdi-cancel';
export const VOLTAR = 'mdi-arrow-left';

export const CADASTROS = 'mdi-account';
export const TABELAS = 'mdi-table';
export const MOVIMENTACOES = 'mdi-swap-horizontal';
export const CONSULTAS = 'mdi-filter';
export const SISTEMA = 'mdi-cog';

export const CLIENTES = 'mdi-account-multiple';
export const FUNCIONARIOS = 'mdi-card-account-details';
export const FORNECEDORES = 'mdi-wallet-travel';
export const PECAS = 'mdi-tools';
export const SERVICOS = 'mdi-face-agent';
export const VEICULOS = 'mdi-car';
export const CLASSIFICACOES = 'mdi-swap-horizontal-bold';
export const SEDES = 'mdi-home';

export const BANCOS = 'mdi-bank';
export const TIPOS_CLIENTES = 'mdi-account-cog';
export const TIPOS_FUNCIONARIOS = 'mdi-card-account-details-outline';
export const TIPOS_OS = 'mdi-clipboard-list-outline';
export const GRUPOS_PECAS = 'mdi-format-list-bulleted-type';
export const PRAZOS_PAGAMENTOS = 'mdi-calendar-clock';
export const FORMAS_PAGAMENTOS = 'mdi-cash-usd-outline';

export const FINANCEIRO = 'mdi-currency-usd-circle';

export const ORDENS_SERVICO = 'mdi-clipboard-list-outline';
export const MOVIMENTO_ESTOQUE = 'mdi-swap-horizontal-circle-outline';

export const USUARIOS = 'mdi-card-account-details';
export const PERMISSOES = 'mdi-format-list-checkbox';
