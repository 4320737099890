import caixaApi from "@/app/services/caixaApi";
import dayjs from "dayjs";

export default {
  namespaced: true,
  state: {
    lancamento: {},
    caixa: [],
    resumo: [],
    filtro: {
      data__gte: dayjs().format('YYYY-MM-DD'),
      data__lte: dayjs().format('YYYY-MM-DD'),
      historico__icontains: '',
      conta_id: '',
      classificacao_id: '',
      sede_id: '',
      tipo: '',
    }
  },

  getters: {
    resumo_nao_zerado(state) {
      return state.resumo.filter((resumo) => resumo.total != 0);
    },
  },

  mutations: {
    listar(state, caixa) {
      state.caixa = caixa.caixa;
      state.resumo = caixa.resumo;
    },

    abrir(state, lancamento) {
      state.lancamento = lancamento;
    },

    salvar(state, lancamento) {
      state.caixa.push(lancamento);

      let valor = lancamento.tipo == "Saída" ? lancamento.valor * -1 : lancamento.valor;
      let resumo = state.resumo.find((r) => r.forma_pagamento == lancamento.forma_pagamento);
      if (resumo) resumo.total = resumo.total + valor;
      else
        state.resumo.push({
          forma_pagamento: lancamento.forma_pagamento,
          total: valor,
        });
    },

    salvarSangria(state, sangria) {
      state.caixa.push(sangria);

      sangria.valor = sangria.valor;

      let resumo = state.resumo.find((r) => r.forma_pagamento == sangria.forma_pagamento);
      if (resumo) resumo.total = resumo.total - sangria.valor;
      else
        state.resumo.push({
          forma_pagamento: sangria.forma_pagamento,
          total: sangria.valor,
        });
    },

    atualizar(state, lancamento) {
      let index = state.caixa.findIndex((lancamento_) => lancamento_.id == lancamento.id);
      if (index > -1) {
        state.caixa.splice(index, 1, lancamento);
      }
    },

    excluir(state, lancamento) {
      let valor = lancamento.tipo == "Entrada" ? lancamento.valor * -1 : lancamento.valor;
      let resumo = state.resumo.find((r) => r.forma_pagamento == lancamento.forma_pagamento);

      if (resumo) resumo.total = resumo.total + valor;
      else
        state.resumo.push({
          forma_pagamento: lancamento.forma_pagamento,
          total: valor,
        });

      let index = state.caixa.findIndex((lancamento_) => lancamento_.id == lancamento.id);
      if (index > -1) {
        state.caixa.splice(index, 1);
      }
    },

    UNSET_LANCAMENTO(state) {
      state.lancamento = null;
    },

    SET_LANCAMENTO(state, lancamento) {
      state.lancamento = lancamento;
    }
  },

  actions: {
    async listar({ commit }, filtro) {
      commit("LOADING_ON", null, { root: true });
      let caixa = await caixaApi.listar(filtro);
      commit("listar", caixa);
      commit("LOADING_OFF", null, { root: true });
    },

    async abrir({ commit }, id) {
      commit("LOADING_ON", null, { root: true });
      let lancamento = await caixaApi.abrir(id);
      commit("abrir", lancamento);
      commit("LOADING_OFF", null, { root: true });
    },

    async salvar({ commit }, lancamento) {
      let novolancamento = await caixaApi.salvar(lancamento);
      commit("salvar", novolancamento);
    },

    async salvarSangria({ commit }, sangria) {
      let novosangria = await caixaApi.salvarSangria(sangria);
      commit("salvarSangria", novosangria);
    },

    async atualizar({ commit }, lancamento) {
      const lancamento_atualizado = await caixaApi.atualizar(lancamento);
      commit("atualizar", lancamento_atualizado);
    },

    async excluir({ commit }, lancamento) {
      await caixaApi.excluir(lancamento.id);
      commit("excluir", lancamento);
    },
  },
};
