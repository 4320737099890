import createAxios from "@/app/core/AxiosCore";
import config from "@/app/config/app";

const axios = createAxios(config);

function listar(paginacao) {
  return axios.get(`sedes`, { params: paginacao }).then((response) => response.data);
}

function buscar(busca) {
  return axios.get(`sedes/buscar?busca=${busca}`).then((response) => response.data);
}

function buscarPorCodigo(codigo) {
  return axios.get(`sedes/buscarCodigo?codigo=${codigo}`).then((response) => response.data);
}

function abrir(id) {
  return axios.get(`/sedes/${id}`).then((response) => response.data);
}

function salvar(sede) {
  return axios.post("/sedes", sede).then((response) => response.data);
}

function atualizar(sede) {
  return axios.put(`/sedes/${sede.id}`, sede).then((response) => response.data);
}

function excluir(id) {
  return axios.delete(`/sedes/${id}`).then((response) => response.data);
}

export default {
  listar,
  buscar,
  buscarPorCodigo,
  abrir,
  salvar,
  atualizar,
  excluir,
};
