<template>
  <v-app-bar app color="primary" dark>
    <v-app-bar-nav-icon v-if="$vuetify.breakpoint.smAndDown" @click="$emit('navIconClick')"></v-app-bar-nav-icon>

    <v-btn icon @click="goHome" v-if="$vuetify.breakpoint.mdAndUp">
      <v-icon>mdi-church</v-icon>
    </v-btn>

    <v-toolbar-title>
      <span class="mr-2 apontador" @click="goHome">{{ config.APP_NAME }}</span>
      <span class="text-caption mr-2">{{ config.APP_VERSION }}</span>
    </v-toolbar-title>

    <template v-if="$vuetify.breakpoint.mdAndUp">
      <v-divider vertical inset class="ml-4"></v-divider>

      <menu-principal></menu-principal>
    </template>

    <v-spacer></v-spacer>
    <v-toolbar-items>
      <app-user-menu></app-user-menu>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex';

import AppUserMenu from './AppUserMenu';
import MenuPrincipal from './menus/MenuPrincipal';
import MenuPrincipalDropdown from './menus/MenuPrincipalDropdown';

import config from '@/app/config/app';

export default {
  components: {
    MenuPrincipal,
    AppUserMenu,
    MenuPrincipalDropdown,
  },

  data() {
    return {
      config,
    };
  },

  methods: {
    goHome() {
      this.$router.push({ name: 'Home' }).catch((error) => {});
    },
  },

  computed: {
    ...mapState(['nameOfState']),
  },
};
</script>

<style scoped></style>
