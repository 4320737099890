import CaixaLista from "@/pages/movimentacoes/caixa/CaixaLista";
import CaixaForm from "@/pages/movimentacoes/caixa/CaixaForm";

export default [
  {
    path: "/movimentacoes/caixa",
    name: "CaixaLista",
    component: CaixaLista,
    meta: { permissao: "caixa_acessar" },
  },
];
