<template>
  <div style="display: inline-block; vertical-align: middle; position: relative">

    <template v-if="!menu">
      <v-btn v-for="(item, index) in first" icon @click.stop="item.click(param)" :class="{'ma-0': noMargin}" :disabled="item.disabled" :key="index">
        <v-icon>{{item.icon}}</v-icon>
      </v-btn>

      <v-menu left offset-y v-if="show_dynamic_menu">
        <v-btn icon slot="activator" :class="{'ma-0': noMargin}">
          <v-icon>more_vert</v-icon>
        </v-btn>
        <v-list>
          <template v-for="(item, index) in items">
            <v-divider v-if="item.divider" :key="index"></v-divider>
            <v-list-item v-else v-for="(item, index) in remaining" :key="index" @click.stop="item.click(param)" :disabled="item.disabled">
              <v-list-item-action v-if="item.icon && showMenuIcons">
                <v-icon>{{item.icon}}</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
    </template>
  
    <v-menu left offset-y v-else>
      <v-btn icon slot="activator" :class="{'ma-0': noMargin}">
        <v-icon>more_vert</v-icon>
      </v-btn>
      <v-list>
        <template v-for="(item, index) in items">
          <v-divider v-if="item.divider" :key="index"></v-divider>
          <v-list-item v-else :key="index" @click.stop="item.click(param)" :disabled="item.disabled">
            <v-list-item-action v-if="item.icon && showMenuIcons">
              <v-icon>{{item.icon}}</v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
  export default {
    name: 'z-dynamic-menu',
    props: {
      items: {
        type: Array,
        required: true
      },
      breakPoint: {
        type: String,
        default: 'sm'
      },
      showButtons: {
        type: Number,
        default: 1
      },
      showMenuIcons: {
        type: Boolean,
        defualt: false
      },
      param: {
        default: null
      },
      noMargin: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      menu() {
        if (this.breakPoint == 'xs' && this.$vuetify.breakpoint.xs) 
          return true;
        if (this.breakPoint == 'sm' && this.$vuetify.breakpoint.smAndDown)
          return true;
        if (this.breakPoint == 'md' && this.$vuetify.breakpoint.mdAndDown)
          return true;
        if (this.breakPoint == 'lg' && this.$vuetify.breakpoint.lgAndDown)
          return true;
        if (this.breakPoint == 'xl') {
          return this.$vuetify.breakpoint.xl || this.$vuetify.breakpoint.lgAndDown;
        }
        return false
      },

      first() {
        if (this.showButtons == this.items.length - 1)
          return this.items;
        else
          return this.items.slice(0, this.showButtons);
      },

      remaining() {
        return this.items.slice(this.showButtons, this.items.length);
      },

      show_dynamic_menu() {
        return this.showButtons < this.items.length - 1
      }
    }
  }
</script>

<style scoped>

</style>