<template>
  <div>
    <z-centralized-toolbar>
      <v-toolbar-items>
        <v-btn id="btn-adicionar" text small color="primary" @click="adicionar">
          <v-icon left>{{ BANCOS_ICON }}</v-icon
          >Adicionar
        </v-btn>

        <v-btn text id="btn-atualizar" @click="refresh" :loading="$store.state.loading">
          <v-icon>mdi-refresh</v-icon>
          <v-tooltip bottom activator="#btn-atualizar">
            Atualizar
          </v-tooltip>
        </v-btn>
      </v-toolbar-items>

      <!-- <v-spacer></v-spacer> -->

      <z-text-field-search v-model="busca" select class="ml-5" @keydown.enter="buscar" @click:clear="limparBusca"></z-text-field-search>
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container>
        <z-cabecalho>{{ $store.state.titulo_tela }}</z-cabecalho>

        <v-card>
          <v-data-table mobile-breakpoint="0" :options.sync="paginacao" :server-items-length="total_itens" :footer-props="$v.FOOTER_PROPS" :headers="headers" :items="contas" item-key="id">
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.id }}</td>
                <td>{{ item.descricao }}</td>
                <td class="text-center">
                  <v-btn icon @click="editar(item)" class="ma-0">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click="perguntarExcluir(item)" class="ma-0">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </z-container>
    </z-scroll-layout>

    <z-dialogo-sim-nao ref="dialogoRemover" titulo="Remover conta?" botaoSim="Remover" botaoNao="Cancelar" :loading="excluindo">
      O conta será removido do sistema.
    </z-dialogo-sim-nao>

    <v-form v-model="form_valido" lazy-validation ref="formulario">
      <z-dialogo ref="dialogoAdicionar" largura="700px" titulo="Conta" dividers persistent @abrir="$refs.formulario.resetValidation()">
        <template v-slot:conteudo>
          <v-row dense>
            <v-col cols="12" sm="12">
              <z-text-field upper v-model="conta.descricao" ref="descricao" label="Descrição" persistent-placeholder :rules="[regras.campoObrigatorio]"></z-text-field>
            </v-col>
          </v-row>
        </template>

        <template v-slot:actions_right>
          <v-btn text @click="$refs.dialogoAdicionar.fechar()" :disabled="salvando">Cancelar</v-btn>
          <v-btn color="primary" ref="btnConfirmar" @click="confirmar" :loading="salvando" :disabled="!form_valido">Confirmar</v-btn>
        </template>
      </z-dialogo>
    </v-form>

    <z-dialogo-pergunta ref="dialogo_pergunta"></z-dialogo-pergunta>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import * as regras from '@/app/helpers/validacoes';
import { BANCOS } from '@/app/icons';

export default {
  name: 'ContasLista',
  data() {
    return {
      headers: [
        { text: 'Código', value: 'id', width: '150px' },
        { text: 'Descrição', value: 'descricao' },
        { text: 'Opções', value: '', width: '200px', align: 'center', sortable: false },
      ],
      excluindo: false,
      salvando: false,
      form_valido: true,
      regras: { ...regras },
      BANCOS_ICON: BANCOS,
    };
  },

  computed: {
    ...mapState(['loading']),
    ...mapState('contas', ['contas', 'conta', 'total_itens']),

    busca: {
      get() {
        return this.$store.state.contas.busca;
      },
      set(busca) {
        this.$store.commit('contas/SET_BUSCA', busca);
      },
    },

    paginacao: {
      get() {
        return this.$store.state.contas.paginacao;
      },
      set(paginacao) {
        this.$store.commit('contas/UPDATE_PAGINACAO', paginacao);
      },
    },
  },

  created() {
    this.refresh();
  },

  mounted() {
    this.setTitulo('Contas');
  },

  methods: {
    ...mapMutations(['setTitulo', 'LOADING_OFF']),
    ...mapActions('contas', ['listar', 'excluir', 'abrir', 'atualizar', 'salvar']),

    async refresh() {
      try {
        await this.listar();
      } catch (error) {
        this.$eventbus.notificar('Não foi possível listar os contas.');
      } finally {
        this.$store.commit('LOADING_OFF', null);
      }
    },

    buscar() {
      this.$store.commit('contas/SET_PAGE', 1);
      this.refresh();
    },

    limparBusca() {
      this.busca = null;
      this.$store.commit('contas/SET_PAGE', 1);
      this.refresh();
    },

    adicionar() {
      this.$store.commit('contas/ZERAR');
      this.$refs.dialogoAdicionar.abrir();
    },

    async editar(conta) {
      try {
        await this.abrir(conta.id);
        this.$refs.dialogoAdicionar.abrir();
      } catch (error) {
        console.log(error);
        this.$eventbus.notificar('Não foi possível abrir o conta.');
      } finally {
        this.LOADING_OFF();
      }
    },

    async confirmar() {
      if (!this.$refs.formulario.validate()) {
        return false;
      }

      try {
        this.salvando = true;

        if (this.conta.id) {
          await this.atualizar(this.conta);
        } else {
          await this.salvar(this.conta);
        }

        this.$refs.dialogoAdicionar.fechar();
        this.$eventbus.notificar('Tipo de conta salvo com sucesso');
      } catch (error) {
        this.$eventbus.notificarErro('Não foi possível salvar esta conta. Verifique os dados preenchidos.', error);
        throw error;
      } finally {
        this.salvando = false;
      }
    },

    async perguntarExcluir(conta) {
      let resposta = await this.$refs.dialogo_pergunta.abrir({
        titulo: 'Remover Conta?',
        texto: 'O conta será removido do sistema.',
        botoes: ['Cancelar', 'Remover'],
        padrao: 'Remover',
        cor: 'red',
      });

      if (resposta == 'Remover') {
        this.$refs.dialogo_pergunta.carregando();
        await this.confirmarExcluir(conta);
        this.$refs.dialogo_pergunta.fechar();
      } else {
        this.$refs.dialogo_pergunta.fechar();
      }
    },

    async confirmarExcluir(conta) {
      try {
        this.excluindo = true;
        await this.excluir(conta.id);
        this.$eventbus.notificar('Conta excluída com sucesso!');
      } catch (error) {
        this.$eventbus.notificar('Não foi possível excluir o conta!');
        throw error;
      } finally {
        this.excluindo = false;
      }
    },
  },

  watch: {
    paginacao: {
      handler() {
        this.refresh();
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
