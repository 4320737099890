<template>
  <v-btn id="btRelatorios" v-bind="$attrs">
    <v-icon left v-if="prependIcon">{{prependIcon}}</v-icon>
    {{title}}
    <v-icon right v-if="appendIcon">{{appendIcon}}</v-icon>

    <v-menu bottom offset-y transition="slide-y-transition" activator="#btRelatorios">
      <v-list nav dense>
        <v-subheader class="text-uppercase text--primary font-weight-black">{{title}}</v-subheader>
        <template>
          <v-list-item v-for="(item, index) in items" :key="index" @click="emitir(item)" v-show="item.visible">
            <v-list-item-icon>
              <v-icon>{{item.icon}}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
  </v-btn>
</template>

<script setup>
import { ref, computed } from "vue";

const props = defineProps({
  title: {
    type: String,
  },

  items: {
    type: Array,
    default: () => []
  },

  prependIcon: {
    type: String,
  },

  appendIcon: {
    type: String,
    default: "mdi-menu-down"
  }
});

const emit = defineEmits(['input']);

function emitir(item) {
  item.action();
  emit('input', item.value);
}

</script>

<style scoped></style>
