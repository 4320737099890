import ContasLista from "@/pages/cadastros/contas/ContasLista";

export default [
  {
    path: "/cadastros/contas",
    name: "ContasLista",
    component: ContasLista,
    meta: { permissao: "contas_acessar" },
  },
];
