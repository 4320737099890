import ClassificacoesLista from "@/pages/cadastros/classificacoes/ClassificacoesLista";

export default [
  {
    path: "/cadastros/classificacoes",
    name: "ClassificacoesLista",
    component: ClassificacoesLista,
    meta: { permissao: "classificacoes_acessar" },
  },
];
