<template>
  <v-app>
    <!-- <v-navigation-drawer v-model="$store.state.drawerRight" fixed right clipped app width="500">
      <v-toolbar flat>
        <v-list>
          <v-list-item>
            <v-list-item-title class="title">
              Application
            </v-list-item-title>
            <v-list-item-action>
              <v-btn text icon @click="$store.commit('TOGGLE_RIGHT_DRAWER')">
                <v-icon>close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-toolbar>
      <v-divider></v-divider>
    </v-navigation-drawer> -->

    <v-app-bar color="primary" dark app fixed clipped-right>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ tituloTela }}</v-toolbar-title>
      <v-progress-circular v-show="loading" indeterminate :size="25" :width="3" color="white" class="ml-4"></v-progress-circular>
      <v-spacer></v-spacer>

      <span v-if="!config.IS_PRODUCTION">[ {{ $vuetify.breakpoint.name }} ]</span>
      <v-btn icon text @click="$router.push('/')" v-if="!config.IS_PRODUCTION">
        <v-icon>mdi-home</v-icon>
      </v-btn>

      <app-user-menu></app-user-menu>
    </v-app-bar>

    <app-drawer width="250" :drawer.sync="drawer"></app-drawer>

    <v-main class="grey lighten-4" style="padding-bottom_: 100px">
      <!-- <v-fade-transition mode='out-in'> -->
      <router-view></router-view>
      <!-- </v-fade-transition> -->
    </v-main>

    <v-snackbar bottom v-model="snackbar.exibir" :timeout="snackbar.timeout" :color="snackbar.color">
      {{ snackbar.mensagem }}
      <v-btn dark text color="primary" @click.native="snackbar.exibir = false">Fechar</v-btn>
      <v-btn dark text color="primary" @click.native="snackbar.exibir = false">Detalhes</v-btn>
    </v-snackbar>

    <z-notificacao ref="notificacao"></z-notificacao>
  </v-app>
</template>

<script>
// import MenuPrincipal from "@/pages/layout/MenuPrincipal";
import AppDrawer from "./AppDrawer";
import AppDrawerClientes from "./AppDrawerClientes";
import MenuPrincipal from "./menus/MenuPrincipal";
import AppUserMenu from "./AppUserMenu";

import events from "@/app/mixins/events";
import config from "@/app/config/app";
import { mapState } from "vuex";

export default {
  name: "AppLayout",
  // mixins: [events],
  components: {
    AppDrawer,
    AppDrawerClientes,
    MenuPrincipal,
    AppUserMenu,
  },
  data() {
    return {
      config: config,
      // drawer_clientes: false,
      clipped: false,
      drawer: true,
      fixed: false,
      items: [
        { icon: "home", title: "Tela Inicial", to: "/" },
        { icon: "card_travel", title: "Catálogo", to: "/catalogo" },
        { icon: "shopping_cart", title: "Vendas", to: "/vendas" },
        { icon: "check_circle", title: "Compras", to: "/compras" },
        { icon: "monetization_on", title: "Financeiro", to: "/financeiro" },
        { icon: "assignment", title: "Relatórios", to: "/relatorios" },
      ],

      items2: [
        {
          icon: "mdi-account-circle",
          href: "#",
          title: "Meu Perfil",
          click: (e) => {
            console.log(e);
          },
        },
        // {
        //   icon: "settings", href: "#", title: "Configurações",
        //   click: e => {
        //     console.log(e);
        //   }
        // },
        {
          icon: "mdi-exit-to-app",
          href: "#",
          title: "Sair",
          click: (e) => {
            this.$eventbus.$emit("APP_LOGOUT", "Tô saindo!");
          },
        },
      ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: "Tela Inicial",
      snackbar: {
        exibir: false,
        mensagem: "",
        timeout: 3000,
        color: "",
      },
    };
  },

  mounted() {
    // this.$store.dispatch('listas/todas');
    this.$store.dispatch("carregarConfiguracoes");
    events.start(this);
  },

  beforeDestroy() {
    events.stop();
  },

  computed: {
    ...mapState({
      tituloTela: (state) => state.titulo_tela,
      loading: (state) => state.loading,
    }),

    styleContent() {
      return {
        paddingLeft: !this.drawer ? "" : "250px !important",
      };
    },
  },
};
</script>
