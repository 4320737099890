const createLocalStorage = function(config) {
  function set(variavel, valor) {
    if (config.STORAGE_ENCRYPT) {
      valor = btoa(JSON.stringify(valor));
    } else {
      valor = JSON.stringify(valor);
    }

    localStorage.setItem(config.APP_PREFIX + variavel, valor);
  }

  function get(variavel) {
    let valor = localStorage.getItem(config.APP_PREFIX + variavel);

    if (valor) {
      if (config.STORAGE_ENCRYPT) {
        valor = atob(valor);
      }

      return JSON.parse(valor);
    }

    return null;
  }

  function remove(variavel) {
    localStorage.removeItem(config.APP_PREFIX + variavel);
  }

  function has(variavel) {
    return !!localStorage.getItem(config.APP_PREFIX + variavel);
  }

  return { set, get, remove, has };
};

export default createLocalStorage;
