import createAxios from "@/app/core/AxiosCore";
import config from "@/app/config/app";

const axios = createAxios(config);

function listar(paginacao) {
  return axios.get(`classificacoes`, { params: paginacao }).then((response) => response.data);
}

function buscar(busca) {
  return axios.get(`classificacoes/buscar?busca=${busca}`).then((response) => response.data);
}

function buscarPorCodigo(codigo) {
  return axios.get(`classificacoes/buscarCodigo?codigo=${codigo}`).then((response) => response.data);
}

function abrir(id) {
  return axios.get(`/classificacoes/${id}`).then((response) => response.data);
}

function salvar(classificacao) {
  return axios.post("/classificacoes", classificacao).then((response) => response.data);
}

function atualizar(classificacao) {
  return axios.put(`/classificacoes/${classificacao.id}`, classificacao).then((response) => response.data);
}

function excluir(id) {
  return axios.delete(`/classificacoes/${id}`).then((response) => response.data);
}

export default {
  listar,
  buscar,
  buscarPorCodigo,
  abrir,
  salvar,
  atualizar,
  excluir,
};
