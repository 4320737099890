<template>
  <v-dialog v-model="exibir" persistent :max-width="largura">
    <v-card>
      <v-card-text class="pa-3">
        <v-row>
          <v-col cols="3">
            <v-progress-circular
              indeterminate
              :size="40"
              :color="color"
            ></v-progress-circular>
          </v-col>
          <v-col cols="9" align-self="center">
            <span class="text--primary subtitle-1">Aguarde um momento.</span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "z-aguardar",

  props: {
    titulo: {
      type: String,
    },
    texto: {
      type: String,
    },
    largura: {
      type: String,
      default: "300px",
    },
    color: {
      type: String,
      default: "green darken-2",
    },
  },

  data() {
    return {
      exibir: false,
    };
  },

  methods: {
    abrir() {
      this.exibir = true;
      this.$emit("abrir");
    },

    fechar() {
      this.exibir = false;
      this.$emit("fechar");
    },

    async executar(cb) {
      this.abrir();
      await cb();
      this.fechar();
    },
  },
};
</script>

<style scoped>
</style>
