<template>
  <div>
    <z-centralized-toolbar>
      <!-- <v-toolbar-items> -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              class="mr-2"
              color="primary"
              depressed
              rounded
              @click="novoLancamento"
            >
              <v-icon left>mdi-plus</v-icon>
              Lançar no Caixa
            </v-btn>
          </template>
          Lançar no Caixa
        </v-tooltip>

        <z-menu-button
          v-model="impressao_selecionada"
          text
          rounded
          title="Imprimir"
          class="mr-2"
          :items="impressoes"
          :prepend-icon="$icon.IMPRIMIR"
        ></z-menu-button>

        <v-btn
          class="mr-2"
          :color="filtrar ? 'primary' : ''"
          :text="!filtrar"
          :depressed="filtrar"
          rounded
          @click="exibirFiltro"
        >
          <v-icon left>mdi-filter</v-icon>
          Filtro
        </v-btn>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              :loading="$store.state.loading"
              @click="refresh"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          Atualizar
        </v-tooltip>
      <!-- </v-toolbar-items> -->



      <v-expand-transition>
        <template v-if="!filtrar">
          <z-date-navigation class="ml-5" v-model="data" @input="refresh" :max-date="hoje()"></z-date-navigation>
        </template>
      </v-expand-transition>
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION" ref="scrollLayout">
      <z-container>

        <v-expand-transition>
          <filtro-caixa
            v-if="filtrar"
            ref="filtroCaixa"
            class="mb-3"
            @filtro:cancelar="cancelarFiltro"
          ></filtro-caixa>
        </v-expand-transition>

        <z-cabecalho>Caixa</z-cabecalho>

        <v-card>
          <v-data-table
            hide-default-footer
            :headers="headers"
            :items="caixa"
            item-key="id"
            :loading="false"
            :search="search"
            disable-pagination
          >
            <template v-slot:item="{ item: caixa }">
              <tr>
                <td>{{ caixa.data | dataBR }}</td>
                <td>{{ caixa.historico }}</td>
                <td>{{ caixa.conta.descricao }}</td>
                <td>{{ caixa.classificacao.descricao }}</td>
                <td>{{ caixa.sede.descricao }}</td>
                <td class="text-center">
                  <v-chip small label text-color="white" :color="caixa.tipo == 'Entrada' ? 'green' : 'red'">{{ caixa.tipo }}</v-chip>
                </td>
                <td class="text-right">{{ caixa.valor | valorBR }}</td>
                <td class="text-center text-no-wrap">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="editar(caixa)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip bottom :hidden="!caixaAtual">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="perguntarExcluir(caixa)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Excluir Lançamento</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </z-container>
    </z-scroll-layout>

    <z-dialogo ref="dialogoExcluir" titulo="Deseja excluir esse lançamento do caixa?" :dividers="true" largura="500px">
      <div slot="texto">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>Data</v-list-item-subtitle>
              <v-list-item-title>{{ lancamento.data | dataBR }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>Descrição</v-list-item-subtitle>
              <v-list-item-title>{{ lancamento.historico }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>Forma de Pagamento</v-list-item-subtitle>
              <v-list-item-title>{{ lancamento.forma_pagamento }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>Tipo</v-list-item-subtitle>
              <v-list-item-title :class="lancamento.tipo == 'Entrada' ? 'green--text' : 'red--text'">{{
                lancamento.tipo
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>Valor</v-list-item-subtitle>
              <v-list-item-title>{{ lancamento.valor | valorBR }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>

      <v-btn text @click="$refs.dialogoExcluir.fechar()" :disabled="excluindo">Cancelar</v-btn>
      <v-btn color="primary darken-1" text @click="confirmarExcluir" :loading="excluindo" :disabled="excluindo"
        >Excluir</v-btn
      >
    </z-dialogo>

    <z-dialogo ref="dialogoLancamento" titulo="Lançamento no Caixa" :dividers="true" largura="900px" eager persistent>
      <template v-slot:texto>
        <v-form ref="form" v-model="form_valido" lazy-validation>
          <v-row dense>
            <v-col cols="12" sm="3">
              <z-date-picker-menu
                v-model="lancamento.data"
                label="Data"
              ></z-date-picker-menu>
            </v-col>
            <v-col cols="12" sm="9">
              <z-text-field
                v-model="lancamento.historico"
                label="Histórico"
                upper
                ref="historico"
                persistent-placeholder
                :rules="regras.obrigatorio"
              ></z-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <z-select v-model="lancamento.conta_id" label="Conta" :items="contas" persistent-placeholder :rules="regras.obrigatorio"></z-select>
            </v-col>
            <v-col cols="12" sm="8">
              <z-select
                v-model="lancamento.classificacao_id"
                label="Classificação/DRE"
                persistent-placeholder
                :items="classificacoes"
                :rules="regras.obrigatorio"
                @change="onChangeClassificacao"
              ></z-select>
            </v-col>
            <v-col cols="12" sm="4">
              <z-select
                v-model="lancamento.sede_id"
                label="Sede/Congregação"
                persistent-placeholder
                :rules="regras.obrigatorio"
                :items="sedes"
              ></z-select>
            </v-col>
            <v-col cols="12" sm="4">
              <z-campo-valor
                v-model="lancamento.valor"
                label="Valor"
                :rules="regras.valor"
                ref="valor"
                persistent-placeholder
              ></z-campo-valor>
            </v-col>
            <v-col cols="12" sm="4">
              <z-select
                v-model="lancamento.tipo"
                label="Tipo"
                :items="$globals.TIPO_LANCAMENTO"
                persistent-placeholder
                readonly
              ></z-select>
            </v-col>
          </v-row>
        </v-form>
      </template>

      <v-btn text @click="$refs.dialogoLancamento.fechar()" :disabled="salvando">Cancelar</v-btn>
      <v-btn
        color="primary"
        text
        :loading="salvando"
        :disabled="salvando || !form_valido"
        @click="confirmarLancamento"
      >
        {{ lancamento.id ? 'Atualizar' : 'Lançar' }}
      </v-btn>
    </z-dialogo>

    <z-dialogo ref="dialogoSangria" titulo="Sangria" :dividers="true" largura="750px" eager>
      <div slot="texto">
        <v-form ref="form_sangria" v-model="form_valido_sangria" lazy-validation>
          <v-container>
            <v-layout row wrap>
              <v-flex sm4>
                <z-date-picker-menu v-model="lancamento.data" label="Data" readonly></z-date-picker-menu>
              </v-flex>
              <v-flex sm4>
                <v-select
                  v-model="lancamento.forma_pagamento"
                  label="Forma de Pagamento"
                  :items="$globals.FORMAS_DE_PAGAMENTO_CAIXA"
                ></v-select>
              </v-flex>
              <v-flex sm4>
                <z-campo-valor
                  ref="valor_sangria"
                  v-model="lancamento.valor"
                  placeholder=" "
                  label="Valor"
                  :rules="regras.valor"
                ></z-campo-valor>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </div>

      <v-btn text @click="$refs.dialogoSangria.fechar()" :disabled="salvando">Cancelar</v-btn>
      <v-btn
        color="primary darken-1"
        text
        :loading="salvando"
        :disabled="salvando || !form_valido_sangria"
        @click="confirmarSangria"
        >Lançar</v-btn
      >
    </z-dialogo>

    <z-dialogo-pergunta ref="dialogo_pergunta"></z-dialogo-pergunta>
    <z-autorizacao-admin ref="dialogoAutorizacaoAdmin"></z-autorizacao-admin>
    <meses-anos ref="dialogoMesesAnos" @imprimir="imprimirCaixaMensal"></meses-anos>
    <dialogo-relatorio-anual-DRE ref="dialogoRelatorioAnualDRE" @imprimir="imprimirRelatorioAnualDRE"></dialogo-relatorio-anual-DRE>

    <z-dialogo-impressao
      ref="dialogoImpressao"
      titulo="Impressão"
      :url-impressao="urlImpressao"
      @fechar="urlImpressao = ''; impressao_selecionada = ''"
    >
      <template #extra_buttons="props">
        <v-btn
          text
          color="primary"
          :disabled="props.loading"
          @click="exportarExcel"
        >
          <v-icon left>mdi-file-excel</v-icon>
          Excel
        </v-btn>
      </template>
    </z-dialogo-impressao>

  </div>
</template>

<script>
  import { mapMutations, mapActions, mapState, mapGetters } from 'vuex';

  import { abrirJanela } from '@/app/helpers/utils';
  import config from '@/app/config/app';

  import MenuImprimir from './MenuImprimir';
  import MesesAnos from './MesesAnos';
  import DialogoRelatorioAnualDRE from './DialogoRelatorioAnualDRE';
  import FiltroCaixa from './FiltroCaixa.vue';

  const BASE_URL_PRINT = config.IS_PRODUCTION ? window.location.origin : config.BASE_URL_PRINT;

  export default {
    name: 'CaixaLista',

    components: {
      MenuImprimir,
      MesesAnos,
      DialogoRelatorioAnualDRE,
      FiltroCaixa
    },

    data() {
      return {
        // globals,
        headers: [
          { text: 'Data', value: 'data', width: '120px' },
          { text: 'Histórico', value: 'historico' },
          { text: 'Conta', value: 'conta.descricao' },
          { text: 'Classificação', value: 'classificacao.descricao' },
          { text: 'Sede', value: 'sede.descricao' },
          { text: 'Tipo', value: 'tipo' },
          { text: 'Valor', value: 'valor', align: 'right' },
          { text: 'Opções', value: '', align: 'center', sortable: false },
        ],
        headers_resumo: [
          { text: 'Forma Pagamento', value: 'forma_pagamento', width: '150px', sortable: false },
          { text: 'Valor', value: 'valor', align: 'right', sortable: false },
        ],
        search: '',
        pagination: {},
        excluindo: false,
        salvando: false,
        regras: {
          obrigatorio: [(v) => !!v || 'Campo obrigatório'],
          valor: [(v) => v != '0,00' || 'O valor não pode ser zero'],
          forma_pagamento: [(v) => !!v || 'A forma de pagamento é obrigatório'],
        },
        form_valido: false,
        form_valido_sangria: false,
        data: this.$dayjs().format('YYYY-MM-DD'),
        exibir_saldo: false,
        urlImpressao: '',

        impressoes: [
          {
            text: 'Caixa do Dia',
            value: 'imprimir_caixa_dia',
            icon: 'mdi-card-text-outline',
            visible: true,
            action: this.imprimirCaixaDia
          },
          {
            text: 'Caixa Mensal (Geral/Congregação)',
            value: 'imprimir_caixa_mensal',
            icon: 'mdi-card-text-outline',
            visible: true,
            action: this.filtroCaixaMensal
          },
          {
            text: 'Relatório Anual DRE',
            value: 'imprimir_dre_anual',
            icon: 'mdi-card-text-outline',
            visible: true,
            action: this.filtroDRE
          },
        ],
        impressao_selecionada: '',
        filtro: {
          sede_id: null,
          mes: null,
          ano: null,
        },

        filtrar: false
      };
    },

    async mounted() {
      this.data = this.$dayjs().format('YYYY-MM-DD');
      this.setTitulo('Movimento de Caixa');
      this.refresh();

      await this.$store.dispatch('listas/sedes');
      await this.$store.dispatch('listas/classificacoes');
      await this.$store.dispatch('listas/contas');
    },

    computed: {
      ...mapState('caixa', ['caixa', 'lancamento']),
      ...mapGetters('listas', ['sedes', 'classificacoes_', 'contas']),

      classificacoes() {
          return this.$store.state.listas.classificacoes;
      },

      caixaAtual() {
        return this.data == this.hoje();
      },

      classificacao() {
        return this.$store.state.listas.classificacoes.find((classificacao) => classificacao.value == this.lancamento.classificacao_id);
      },

      config() {
        return this.$store.state.configuracoes;
      },
    },

    methods: {
      ...mapMutations(['setTitulo', 'LOADING_OFF', 'SET_LANCAMENTO']),
      ...mapActions('caixa', ['listar', 'excluir', 'abrir', 'salvar', 'salvarSangria', 'atualizar']),

      async refresh() {
        try {
          await this.listar({data: this.data});
        } catch (error) {
          this.$eventbus.notificarErro('Não foi possível obter a lista do caixa.', error);
        } finally {
          this.LOADING_OFF();
        }
      },

      async editar(caixa) {
        await this.abrir(caixa.id);
        this.$refs.dialogoLancamento.abrir();
      },

      novoLancamento() {
        this.$refs.form.resetValidation();
        this.$store.commit('caixa/SET_LANCAMENTO',  {
          data: this.data,
          historico: '',
          tipo: 'Saída',
          valor: 0,
          conta_id: null,
          classificacao_id: null,
          sede_id: null,
        });
        // this.setFocus("historico");
        this.$refs.dialogoLancamento.abrir();
      },

      onChangeClassificacao() {
        this.lancamento.tipo = this.classificacao.tipo == 'Despesa' ? 'Saída' : 'Entrada';
      },

      novaSangria() {
        this.$refs.form_sangria.resetValidation();
        this.lancamento = {
          data: this.$dayjs().format('YYYY-MM-DD'),
          historico: 'Sangria do Caixa',
          tipo: 'Sangria',
          forma_pagamento: 'Dinheiro',
          valor: 0,
        };
        this.setFocus('valor_sangria');
        this.$refs.dialogoSangria.abrir();
      },

      async confirmarLancamento() {

        if (!this.$refs.form.validate()) {
          return false;
        }

        if (this.isDataLancamentoRetroativa(this.lancamento.data) && !this.lancamento.id) {
          const resposta = await this.autorizarDataRetroativa();
          if (!resposta) {
            return false;
          }
        }

        this.salvando = true;
        try {

          if (this.lancamento.id) {
            await this.atualizar(this.lancamento);
          } else {
            await this.salvar(this.lancamento);
          }
          this.$refs.dialogoLancamento.fechar();
        } catch (error) {
          this.$eventbus.erro('Não foi possível salvar o lançamento.', error);
          this.LOADING_OFF();
        } finally {
          this.salvando = false;
        }
      },

      async confirmarSangria() {
        if (this.$refs.form_sangria.validate()) {
          this.salvando = true;
          try {
            await this.salvarSangria(this.lancamento);
            this.$refs.dialogoSangria.fechar();
          } catch (error) {
            this.$eventbus.erro('Não foi possível salvar a sangria.', error);
            this.LOADING_OFF();
          } finally {
            this.salvando = false;
          }
        }
      },

      async perguntarExcluir(lancamento) {
        let resposta = await this.$refs.dialogo_pergunta.abrir({
          titulo: 'Remover Lançamento',
          texto: 'O Lançamento selecionado será removido do sistema.',
          botoes: ['Cancelar', 'Remover'],
          padrao: 'Remover',
          cor: 'red',
        });

        if (resposta == 'Remover') {
          try {
            // this.excluindo = true;
            this.$refs.dialogo_pergunta.carregando();
            await this.excluir(lancamento);
            this.$eventbus.notificar('Lançamento de caixa excluído com sucesso!');
            this.$refs.dialogo_pergunta.fechar();
          } catch (error) {
            this.$eventbus.notificarErro('Não foi possível excluir esse lançamento.', error);
          }
        } else {
          this.$refs.dialogo_pergunta.fechar();
        }
      },

      async confirmarExcluir() {
        try {
          this.excluindo = true;
          await this.excluir(this.lancamento);
          this.$eventbus.notificar('Lançamento de caixa excluído com sucesso!');
        } catch (error) {
          this.$eventbus.erro('Não foi possível excluir esse lançamento.', error);
        } finally {
          this.$refs.dialogoExcluir.fechar();
          this.excluindo = false;
        }
      },

      corChip(situacao) {
        switch (situacao) {
          case 'Em Caixa':
            return 'green';
            break;

          case 'Compensado':
            return 'blue';
            break;

          case 'Devolvido':
            return 'red';
            break;

          case 'Repassado':
            return 'orange';
            break;

          default:
            break;
        }
      },

      abrirRelatorio(relatorio) {
        // if (relatorio == 'imprimir_caixa_dia') {
        //   this.imprimirCaixaDia();
        // }

        // if (relatorio == 'imprimir_caixa_mensal') {
        //   this.$refs.dialogoMesesAnos.abrir();
        // }

        // if (relatorio == 'imprimir_dre_anual') {
        //   this.$refs.dialogoMesesAnos.abrir();
        // }
      },

      imprimirCaixaDia() {
        this.$refs.dialogoImpressao.abrir();
        // let print_url = config.IS_PRODUCTION ? window.location.origin : config.BASE_URL_PRINT;
        this.urlImpressao = `${BASE_URL_PRINT}/impressoes/caixa/diario?data=${this.data}`;
      },

      exportarExcel() {

        switch (this.impressao_selecionada) {
          case 'imprimir_caixa_dia':
            this.exportarCaixaDiaExcel();
            break;
          case 'imprimir_caixa_mensal':
            this.exportarCaixaMensalExcel();
            break;
        }

      },

      exportarCaixaDiaExcel() {
        abrirJanela(`${BASE_URL_PRINT}/exportar-caixa-excel?data=${this.data}`);
      },

      exportarCaixaMensalExcel() {
        let { sede_id, mes, ano } = this.filtro;
        abrirJanela(`${BASE_URL_PRINT}/exportar-caixamensal-excel?sede_id=${sede_id}&mes=${mes}&ano=${ano}`);
      },

      filtroCaixaMensal() {
        this.$refs.dialogoMesesAnos.abrir();
      },

      imprimirCaixaMensal(filtro) {
        this.filtro = filtro;
        let { sede_id, mes, ano } = filtro;
        sede_id = sede_id == null ? '' : sede_id;
        setTimeout(() => {
          this.$refs.dialogoImpressao.abrir();
          this.urlImpressao = `${BASE_URL_PRINT}/impressoes/caixa/mensal?sede_id=${sede_id}&mes=${mes}&ano=${ano}`;
        }, 300);
      },

      filtroDRE() {
        this.$refs.dialogoRelatorioAnualDRE.abrir();
      },

      imprimirRelatorioAnualDRE({ ano }) {
        console.log(ano);
        setTimeout(() => {
          this.$refs.dialogoImpressao.abrir();
          this.urlImpressao = `${BASE_URL_PRINT}/impressoes/caixa/dreanual?ano=${ano}`;
        }, 300);
      },

      isDataLancamentoRetroativa(data_lancamento) {
        if (this.config.DIAS_LIMITE_PARA_LANCAMENTO_NO_CAIXA > 0) {
          const data = this.$dayjs(data_lancamento);
          const hoje = this.$dayjs();
          return hoje.diff(data, 'day') > this.config.DIAS_LIMITE_PARA_LANCAMENTO_NO_CAIXA;
        } else {
          return false;
        }
      },

      async autorizarDataRetroativa() {
        let resposta = await this.$refs.dialogoAutorizacaoAdmin.abrir({
          titulo: 'Autorizar Lançamento',
          texto: 'O data de lançamento selecionada é menor que a data de atual. É necessário a autorização de um Administrador para efetuar essa operação.',
        });

        return resposta;
      },

      exibirFiltro() {
        if (this.filtrar) {
          this.cancelarFiltro();
        } else {
          this.$refs.scrollLayout.$el.scrollTo(0, 0);
          setTimeout(() => {
            this.filtrar = !this.filtrar;
          }, 100);
        }
      },

      async cancelarFiltro() {
        this.data = this.$dayjs().format('YYYY-MM-DD');
        await this.refresh();
        this.filtrar = false;
      },
    },
  };
  </script>

  <style scoped></style>
