<template>
  <div>
    <v-container>
      <!-- <h3 class="headline">{{ titulo }} Cheque</h3> -->


      <v-form ref="form" v-model="form_valido" lazy-validation>
        <v-container grid-list-lg class="px-0">
          <v-layout row wrap>
            <v-flex>
              <v-card>
                <v-card-title>
                  Dados do Cheque
                </v-card-title>
                <v-divider></v-divider>

                <!-- <z-loading :loading="$store.state.loading" height="100px" color="primary"></z-loading> -->

                <v-container grid-list-md>

                  <!-- <v-layout row wrap>
                    <h3 class="subheader">Usuário</h3>
                  </v-layout> -->
                  <v-layout row wrap>
                    <v-flex sm4 class="py-0">
                      <v-autocomplete placeholder=" " label="Banco" :rules="regras.banco" :items="$store.getters['listas/bancos']" v-model="cheque.banco" ></v-autocomplete>
                    </v-flex>
                    <v-flex sm2 class="py-0">
                      <v-text-field placeholder=" " label="Agência" :rules="regras.agencia" v-model="cheque.agencia"></v-text-field>
                    </v-flex>
                    <v-flex sm2 class="py-0">
                      <v-text-field placeholder=" " label="Conta" :rules="regras.conta" v-model="cheque.conta"></v-text-field>
                    </v-flex>
                    <v-flex sm2 class="py-0">
                      <v-text-field placeholder=" " label="Número" :rules="regras.numero" v-model="cheque.numero"></v-text-field>
                    </v-flex>
                    <v-flex sm2 class="py-0">
                      <z-campo-valor placeholder=" " label="Valor" :rules="regras.valor" v-model="cheque.valor"></z-campo-valor>
                    </v-flex>
                    <v-flex sm2 class="py-0">
                      <z-date-picker-menu placeholder=" " label="Vencimento" :rules="regras.vencimento" v-model="cheque.vencimento"></z-date-picker-menu>
                    </v-flex>
                    <v-flex sm3 class="py-0">
                      <z-cpf-cnpj placeholder=" " label="CPF/CNPJ" :rules="regras.cpfcnpj" v-model="cheque.cpfcnpj"></z-cpf-cnpj>
                    </v-flex>
                    <v-flex sm5 class="py-0">
                      <v-text-field placeholder=" " label="Cidade" :rules="regras.cidade" v-model="cheque.cidade"></v-text-field>
                    </v-flex>
                    <v-flex sm2 class="py-0">
                      <v-autocomplete placeholder=" " label="Estado" :rules="regras.estado" v-model="cheque.estado" :items="$globals.ESTADOS"></v-autocomplete>
                    </v-flex>
                    <v-flex sm5 class="py-0">
                      <v-text-field placeholder=" " label="Nome do Emitente" :rules="regras.emitente" v-model="cheque.emitente"></v-text-field>
                    </v-flex>
                    <v-flex sm2 class="py-0">
                      <v-text-field placeholder=" " label="Telefone Emitente" v-model="cheque.telefone" mask="(##) #####-####" return-masked-value></v-text-field>
                    </v-flex>
                    <v-flex sm5 class="py-0">
                      <v-autocomplete placeholder=" " label="Cliente Responsável" :rules="regras.responsavel" :items="$store.getters['listas/clientes']" v-model="cheque.cliente_id" ></v-autocomplete>
                    </v-flex>
                  </v-layout>


                  <v-layout row wrap>
                    <v-flex sm12>
                        <v-textarea label="Observações" placeholder="Observações sobre o  cheque" v-model="cheque.observacoes"></v-textarea>
                    </v-flex>
                  </v-layout>
                </v-container>


                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text  @click="$router.push('/cheques/controle')" :disabled="salvando">Cancelar</v-btn>
                  <v-btn color="primary" @click="confirmar" :loading="salvando">Salvar</v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>

          </v-layout>
        </v-container>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
export default {
    name: 'cheques-form',
    data() {
        return {
          cheque: {
            telefone: ''
          },
          salvando: false,
          form_valido: false,
          regras: {
            banco: [v => !!v || 'O banco é obrigatório'],
            agencia: [v => !!v || 'O agência é obrigatório'],
            conta: [v => !!v || 'O conta é obrigatório'],
            numero: [v => !!v || 'O numero é obrigatório'],
            valor: [v => v != '0,00' || 'O valor é obrigatório'],
            vencimento: [v => !!v || 'O vencimento é obrigatório'],
            cpfcnpj: [v => !!v || 'O cpf/cnpj é obrigatório'],
            cidade: [v => !!v || 'O cidade é obrigatório'],
            estado: [v => !!v || 'O estado é obrigatório'],
            emitente: [v => !!v || 'O emitente é obrigatório'],
            responsavel: [v => !!v || 'O responsável é obrigatório'],
          },
          tab: '',
        };
    },

    beforeRouteLeave(to, from, next) {
        this.UNSET_CHEQUE();
        next();
    },

    async mounted() {
      this.setTitulo(this.titulo);
      this.$store.dispatch('listas/clientes');
      this.$store.dispatch('listas/bancos');

        if (this.$route.name == "ChequesEditar") {
            if (!this.$store.state.cheques.cheque) {
                if (this.$route.params.id) {
                    await this.abrir(this.$route.params.id);
                }
            }
            this.cheque = this.clone(this.$store.state.cheques.cheque);
        }
    },

    methods: {
        ...mapMutations(["setTitulo"]),
        ...mapMutations("cheques", ["UNSET_CHEQUE"]),
        ...mapActions("cheques", ["abrir", "atualizar", "salvar"]),

        async confirmar() {
          if (this.$refs.form.validate()) {
            try {
              this.salvando = true;

              if (this.$route.params.id) {
                  await this.atualizar(this.cheque);
              } else {
                  await this.salvar(this.cheque);
              }
              
              this.$router.push({ path: "/cheques/controle" });
              this.$eventbus.notificar("Cheque salvo com sucesso");
            } catch(error) {
              this.$eventbus.erro('Não foi possível salvar este Cheque. Verifique os dados do cheque e tente novamente.', error);
            } finally {
               this.salvando = false;
            }
          }
        },
    },

    computed: {
      titulo() {
        let titulo = this.$route.meta.editando ? 'Editar' : 'Adicionar';
        return titulo + ' ' + 'Cheque';
      }
    },
};
</script>

<style scoped>
/* .container.grid-list-md .layout .flex{
    padding-top: 0px;
    padding-right: 4px;
    padding-bottom: 0px;
    padding-left: 4px;
} */
</style>
