<template>
  <v-dialog persistent v-model="exibir" lazy full-width width="290px">
    <v-text-field
      v-bind="attributes"
      slot="activator"
      v-model="dataFormatada"
      prepend-icon="event"
      readonly
    ></v-text-field>
    <v-date-picker
      v-model="data"
      @change="onChange"
      scrollable
      locale="pt-BR"
      :color="color"
    >
      <v-spacer></v-spacer>
      <v-btn text :color="color" @click="exibir = false">Cancelar</v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
import form_mixin from "./form_mixin";

export default {
  name: "z-date-picker",

  mixins: [form_mixin],
  
  props: {
    value: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      data: null,
      dataFormatada: null,
      exibir: false,
    };
  },

  computed: {
    attributes() {
      return { ...this.$attrs, ...this.$v.TEXT_FIELDS };
    },
  },

  methods: {
    onChange(data) {
      this.dataFormatada = this.formatarData(data);
      this.exibir = false;
      data = data.replace(/[-/]/g, "-");
      this.$emit("input", data);
    },

    formatarData(data) {
      if (!data) {
        return null;
      }
      data = data.replace(/[-/]/g, "-");
      return data.split("-").reverse().join("/");
    },
  },

  watch: {
    value(val, oldval) {
      if (val) {
        this.data = val.replace(/[-/]/g, "-");
        this.dataFormatada = this.formatarData(this.data);
      } else {
        this.data = null;
        this.dataFormatada = null;
      }
    },
  },
};
</script>

<style scoped>
</style>
