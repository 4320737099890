import { render, staticRenderFns } from "./ContasLista.vue?vue&type=template&id=f0b63faa&scoped=true&"
import script from "./ContasLista.vue?vue&type=script&lang=js&"
export * from "./ContasLista.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0b63faa",
  null
  
)

export default component.exports