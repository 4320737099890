<template>
  <div :style="style" class="scrollable">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'z-scroll-layout',
    props: {
      topPosition: {
        type: [String, Number],
        default: 48
      },
    },

    data() {
      return {
        style: {
          height: '0px',
          paddingBottom: '0px'
        }
      }
    },

    mounted() {
      this.style.height = `calc(100vh - ${this.topPosition}px)`;
      this.style.paddingBottom = '150px';
      this.$vuetify.goTo(0);
    },

  }
</script>

<style scoped>
  .scrollable {
    overflow-y: auto
  }
</style>