<template>
  <v-slide-y-transition mode="out-in">
    <template v-if="$route.meta.layout == 'full'">
      <transition>
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </transition>
    </template>
    <layout-principal2 v-else></layout-principal2>
  </v-slide-y-transition>
</template>

<script>
import LayoutPrincipal from "@/pages/layout/AppLayout.vue";
import LayoutPrincipal2 from "@/pages/layout/AppLayout2.vue";

export default {
  name: "App",
  components: {
    LayoutPrincipal,
    LayoutPrincipal2
  },

  data() {
    return {};
  },

  // created() {
  //   this.$axios.interceptors.response.use(undefined, function (err) {
  //     return new Promise(function (resolve, reject) {
  //       if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
  //         this.$auth.logout();
  //         this.$router.push('/login');
  //       }
  //       throw err;
  //     });
  //   });
  // },

  created() {
    // this.$store.dispatch('carregarConfiguracoes');
  },

  mounted() {
    // if (this.$auth.check()) {
    //   console.log('Está logado');
    // } else {
    //   console.log('NÃO está logado');
    // }
  }
};
</script>

<style>

  .x-small {
      font-size: 11px;
      height: 18px;
  }

</style>
