import sedesApi from "@/app/services/sedesApi";

export default {
  namespaced: true,
  state: {
    sede: {
      id: null,
      descricao: "",
    },
    sedes: [],
    busca: "",
    total_itens: 0,
    paginacao: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ["id"],
      sortDesc: [true],
    },
  },

  mutations: {
    LISTAR(state, sedes) {
      state.sedes = sedes;
    },

    ABRIR(state, sede) {
      state.sede = sede;
    },

    ADICIONAR(state, sede) {
      state.sedes.unshift(sede);
    },

    ATUALIZAR(state, sede) {
      let index = state.sedes.findIndex((sede_) => sede_.id == sede.id);
      if (index > -1) {
        state.sedes.splice(index, 1, sede);
      }
    },

    EXCLUIR(state, id) {
      let index = state.sedes.findIndex((sede) => sede.id == id);
      if (index > -1) {
        state.sedes.splice(index, 1);
      }
    },

    SET_BUSCA(state, busca) {
      state.busca = busca;
    },

    SET_PAGE(state, page) {
      state.paginacao.page = page;
    },

    SET_TOTAL_ITENS(state, total_itens) {
      state.total_itens = total_itens;
    },

    UPDATE_PAGINACAO(state, paginacao) {
      state.paginacao = paginacao;
    },

    ZERAR(state) {
      state.sede = {
        id: null,
        descricao: "",
      };
    },
  },

  actions: {
    async listar({ commit, state }) {
      commit("LOADING_ON", null, { root: true });

      const { sortBy, sortDesc, page, itemsPerPage } = state.paginacao;

      const paginacao = {
        page,
        itemsPerPage,
        sortBy: sortBy[0],
        sortDesc: sortDesc[0],
      };

      if (state.busca) {
        paginacao.busca = state.busca;
      }

      let response = await sedesApi.listar(paginacao);
      commit("LISTAR", response.data);
      commit("SET_TOTAL_ITENS", response.total);
      commit("LOADING_OFF", null, { root: true });
    },

    async abrir({ commit }, id) {
      commit("LOADING_ON", null, { root: true });
      let sede = await sedesApi.abrir(id);
      commit("ABRIR", sede);
      commit("LOADING_OFF", null, { root: true });
    },

    async salvar({ commit }, sede) {
      let novo = await sedesApi.salvar(sede);
      commit("ADICIONAR", novo);
    },

    async atualizar({ commit }, sede) {
      await sedesApi.atualizar(sede);
      commit("ATUALIZAR", sede);
    },

    async excluir({ commit }, id) {
      await sedesApi.excluir(id);
      commit("EXCLUIR", id);
    },
  },
};
