import Vue from "vue";
import Vuex from "vuex";

import config from "@/app/config/app";
import createAxios from "@/app/core/AxiosCore";

const axios = createAxios(config);

Vue.use(Vuex);

import usuarios from "./modules/usuarios";
import listas from "./modules/listas";
import caixa from "./modules/caixa";
import contas from "./modules/contas";
import classificacoes from "./modules/classificacoes";
import sedes from "./modules/sedes";

const createStore = () => {
  return new Vuex.Store({
    state: {
      titulo_tela: config.APP_TITLE,
      loading: false,
      drawerRight: false,
      configuracoes: {},
      lista_configuracoes: [],
      drawer: true,
    },

    mutations: {
      setTitulo(state, titulo) {
        state.titulo_tela = titulo;
      },

      LOADING(state, loading) {
        state.loading = loading;
      },

      LOADING_ON(state) {
        state.loading = true;
      },

      LOADING_OFF(state) {
        state.loading = false;
      },

      TOGGLE_RIGHT_DRAWER(state) {
        state.drawerRight = !state.drawerRight;
      },

      SET_CONFIGURACOES(state, configuracoes) {
        state.configuracoes = configuracoes;
      },

      SET_LISTA_CONFIGURACOES(state, configuracoes) {
        state.lista_configuracoes = configuracoes;
      },
    },

    actions: {
      carregarConfiguracoes({ commit }) {
        axios.get("configuracoes").then((response) => {
          commit("SET_CONFIGURACOES", response.data);
        });
      },

      carregarListaConfiguracoes({ commit }) {
        return axios.get("configuracoes/lista").then((response) => {
          commit("SET_LISTA_CONFIGURACOES", response.data);
        });
      },

      atualizar({ commit }, configuracao) {
        return axios.put("configuracoes", configuracao);
      },

      salvar({ commit }, configuracao) {
        return axios.post("configuracoes", configuracao);
      },
    },

    modules: {
      usuarios,
      listas,
      caixa,
      contas,
      classificacoes,
      sedes,
    },
  });
};

export default createStore;
