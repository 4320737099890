<template>
  <v-autocomplete ref="input" v-bind="attributes" v-on="$listeners"></v-autocomplete>
</template>

<script>
import form_mixin from "./form_mixin";

export default {
  name: "z-select",

  mixins: [form_mixin],

  computed: {
    attributes() {
      return { ...this.$attrs, ...this.$v.TEXT_FIELDS };
    },
  },
}
</script>

<style lang="scss" scoped>
</style>