<template>
  <v-card>

    <v-card-title class="text-body-1">
      Filtro Caixa
    </v-card-title>

    <v-divider></v-divider>

    <v-card-text>

      <v-row dense>
        <v-col cols="12" sm="3">
          <z-date-picker-menu
            v-model="filtro.data__gte"
            label="Data Inicial"
            show-today
          ></z-date-picker-menu>
        </v-col>
        <v-col cols="12" sm="3">
          <z-date-picker-menu
            v-model="filtro.data__lte"
            label="Data Final"
          ></z-date-picker-menu>
        </v-col>
        <v-col cols="12" sm="6">
          <z-text-field
            v-model="filtro.historico__icontains"
            label="Histórico"
            upper
            ref="historico"
            persistent-placeholder
            clearable
          ></z-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <z-autocomplete
            v-model="filtro.conta_id"
            label="Conta"
            :items="proxy.$store.getters['listas/contas']"
            persistent-placeholder
            clearable
          ></z-autocomplete>
        </v-col>
        <v-col cols="12" sm="6">
          <z-autocomplete
            v-model="filtro.classificacao_id"
            label="Classificação/DRE"
            persistent-placeholder
            clearable
            :items="proxy.$store.state.listas.classificacoes"
          ></z-autocomplete>
        </v-col>
        <v-col cols="12" sm="6">
          <z-autocomplete
            v-model="filtro.sede_id"
            label="Sede/Congregação"
            persistent-placeholder
            clearable
            :items="proxy.$store.getters['listas/sedes']"
          ></z-autocomplete>
        </v-col>
        <v-col cols="12" sm="6">
          <z-select
            v-model="filtro.tipo"
            label="Tipo"
            clearable
            :items="$globals.TIPO_LANCAMENTO"
          ></z-select>
        </v-col>
      </v-row>

    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancelar">Cancelar</v-btn>
        <v-btn depressed color="primary" @click="filtrar">Filtrar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { ref, computed, watch, getCurrentInstance} from 'vue';

const { proxy } = getCurrentInstance();
const emit = defineEmits(['filtro:cancelar']);

const filtro = proxy.$store.state.caixa.filtro;

const cancelar = () => {

  proxy.$store.state.caixa.filtro = {
    data__gte: proxy.$dayjs().format('YYYY-MM-DD'),
    data__lte: proxy.$dayjs().format('YYYY-MM-DD'),
    historico__icontains: '',
    conta_id: '',
    classificacao_id: '',
    sede_id: '',
    tipo: '',
  }

  emit('filtro:cancelar');
}

const filtrar = () => {
  proxy.$store.dispatch('caixa/listar', proxy.$store.state.caixa.filtro);
}

defineExpose({
  cancelar,
  filtrar
});

</script>

<style lang="scss" scoped>

</style>se
