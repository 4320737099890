<template>
  <div class="d-flex justify-space-between align-center">
    <v-btn v-if="!semSetas" text icon @click="subDate" :disabled="disabled">
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>

    <v-menu :disabled="disabled" ref="menu" left :close-on-content-click="false" v-model="menu" transition="scale-transition" origin="center center" offset-y max-width="290px" min-width="290px">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" depressed rounded :color="color" :disabled="disabled">{{ dateFormatted }}</v-btn>
      </template>
      <v-date-picker color="primary" locale="pt-BR" v-model="date" no-title @input="menu = false" :max="maxDate">
        <v-btn text @click="setHoje">Hoje</v-btn>
      </v-date-picker>
    </v-menu>

    <v-btn v-if="!semSetas" text icon @click="addDate" :disabled="disabled || disabledIfMaxDate">
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String],
      default: null,
    },
    disabled: Boolean,
    semSetas: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primary",
    },
    maxDate: {
      type: String
    }
  },

  data: (vm) => ({
    menu: false,
  }),

  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;

      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        date
          .getDate()
          .toString()
          .padStart(2, "0")
      );
    },

    addDate() {
      const [year, month, day] = this.date.split("-");
      let data = new Date(year, month - 1, day);
      data.setDate(data.getDate() + 1);

      this.date = this.parseDate(new Date(data));
    },

    subDate() {
      const [year, month, day] = this.date.split("-");
      let data = new Date(year, month - 1, day);
      data.setDate(data.getDate() - 1);

      this.date = this.parseDate(new Date(data));
    },

    setHoje() {
      this.date = this.hoje();
    },

    hoje() {
      let data = new Date();
      return data.getFullYear() + '-' + (data.getMonth() + 1).toString().padStart(2, '0') + '-' + data.getDate().toString().padStart(2, '0');
    },
  },

  computed: {
    date: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },

    dateFormatted() {
      return this.formatDate(this.date);
    },

    disabledIfMaxDate() {
      return this.date == this.maxDate;
    }
  },
};
</script>

<style scoped></style>
