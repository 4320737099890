import Vue from 'vue';
import Router from 'vue-router';

import Home from '@/pages/Home';

import UsuariosRoutes from './usuarios';
import PublicoRoutes from './publico';

import CaixaRoutes from './caixa';
import ContasRoutes from './contas';
import ClassificacoesRoutes from './classificacoes';
import SedesRoutes from './sedes';

import { guards } from './guards.js';

Vue.use(Router);

const router = new Router({
  mode: 'hash',

  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },

  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },

    {
      path: '*',
      meta: { public: true },
      redirect: { path: '/404' },
    },

    ...UsuariosRoutes,
    ...PublicoRoutes,
    ...CaixaRoutes,
    ...ContasRoutes,
    ...ClassificacoesRoutes,
    ...SedesRoutes,
  ],
});

router.beforeEach(guards);

export default router;
