<template>
  <v-dialog v-model="exibir" persistent :max-width="largura" :return-value="resposta">
    <v-card>
      <v-card-title v-show="titulo">{{ titulo }}</v-card-title>

      <v-card-text>
        <slot>{{ texto }}</slot>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text ref="btnNao" @click="responder(false)" :disable="loading">{{ botaoNao }}</v-btn>
        <v-btn text ref="btnSim" :color="corBotaoSim" :loading="loading" @click="responder(true)">{{ botaoSim }}</v-btn >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "z-dialogo-sim-nao",

  props: {
    titulo: {
      type: String,
      default: null,
    },
    texto: {
      type: String,
      default: null,
    },
    largura: {
      type: String,
      default: "400px",
    },
    dividers: {
      type: Boolean,
      default: false,
    },
    botaoSim: {
      type: String,
      default: "Sim",
    },
    botaoNao: {
      type: String,
      default: "Não",
    },
    corBotaoSim: {
      type: String,
      default: "primary",
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      exibir: false,
      resposta: false,
      resolve: null,
      reject: null,
    };
  },

  methods: {
    abrir() {
      this.exibir = true;
      this.$emit("abrir");
      this.focusBtnSim();
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    fechar() {
      this.exibir = false;
      this.$emit("fechar");
    },

    responder(resposta) {
      if (resposta == false) this.fechar();

      this.resolve(resposta);
    },

    rejeitar(resposta) {
      this.fechar();
      this.reject(resposta);
    },

    focusBtnSim() {
      setTimeout(() => {
        this.$refs.btnSim.$el.focus();
      }, 300);
    }
  },
};
</script>

<style scoped>
</style>
