<template>
  <h3 class="text-body-1 mb-3" v-bind="$attrs">
    <slot></slot>
  </h3>
</template>

<script>
  export default {
    name: 'z-cabecalho',
  }
</script>

<style scoped>

</style>