<template>
  <div>
    <v-container v-if="loading">
      <v-row align="center" :style="style">
        <v-col align="center">
          <v-progress-circular indeterminate :size="size" :width="width" :color="color"></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>

    <slot v-else></slot>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 50
    },
    width: {
      type: Number,
      default: 4
    },
    color: {
      type: String,
      default: "primary"
    },
    height: {
      type: String,
      default: "300px"
    },
    marginTop: {
      type: String,
      default: "0px"
    }
  },

  computed: {
    style() {
      return {
        'height': this.height, 
        'margin-top': this.marginTop
      }
    }
  },
};
</script>

<style scoped>
</style>
