import createAxios from "@/app/core/AxiosCore";
import config from "@/app/config/app";

const axios = createAxios(config);

function sedes() {
  return axios.get("listas/sedes").then((response) => response.data);
}

function classificacoes() {
  return axios.get("listas/classificacoes").then((response) => response.data);
}

function contas() {
  return axios.get("listas/contas").then((response) => response.data);
}

function perfis() {
  return axios.get("listas/perfis").then((response) => response.data);
}

function admins() {
  return axios.get("listas/admins").then((response) => response.data);
}

export default {
  sedes,
  classificacoes,
  contas,
  perfis,
  admins
};
