<template>
  <z-dialogo ref="dialogo" titulo="Relatório Anual DRE" :dividers="true" largura="500px" persistent>
    <template #conteudo>
      <v-row dense>
        <!-- <v-col cols="12" sm="12">
          <z-select
            v-model="filtro.sede_id"
            label="Sede/Congregação"
            clearable
            hint="(Opcional)"
            persistent-hint
            :items="sedes"
          ></z-select>
        </v-col> -->
        <!-- <v-col cols="12" sm="6">
          <z-select
            v-model="filtro.mes"
            label="Mês"
            persistent-placeholder
            :items="meses"
          ></z-select>
        </v-col> -->
        <v-col cols="12" sm="12">
          <z-select
            v-model="filtro.ano"
            label="Ano"
            persistent-placeholder
            :items="anos"
          ></z-select>
        </v-col>
      </v-row>
    </template>

    <template #actions_right>
      <v-btn text @click="$refs.dialogo.fechar()">
        Cancelar
      </v-btn>
      <v-btn text color="primary" @click="imprimir">
        <v-icon left>mdi-printer</v-icon>
        Imprimir
      </v-btn>
    </template>
  </z-dialogo>
</template>

<script>
import globals from '@/app/globals';

import { mapGetters } from 'vuex';

export default {
  name: 'DialogoRelatorioAnualDRE',

  props: {
    relatorio: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      aberto: false,
      filtro: {
        sede_id: '',
        mes: this.$dayjs().format('M'),
        ano: this.$dayjs().format('YYYY'),
      },
      meses: globals.MESES,
    };
  },

  computed: {
    ...mapGetters('listas', ['sedes']),

    anos() {
      let anos = [];
      for (let ano = 2015; ano <= this.$dayjs().format('YYYY'); ano++) {
        anos.push(ano.toString());
      }
      return anos.reverse();
    },
  },

  methods: {
    abrir() {
      this.$refs.dialogo.abrir();
    },

    fechar() {
      this.$refs.dialogo.fechar();
    },

    imprimir() {
      this.$emit('imprimir', this.filtro);
    },
  },
};
</script>

<style scoped></style>
