<template>
  <div>
    <z-container class="mt-10">
      <v-row justify="center">
        <v-col cols="12" align="center" class="mb-4">
          <v-avatar color="primary" size="200">
            <v-icon size="150" dark>mdi-church</v-icon>
          </v-avatar>
        </v-col>

        <v-col cols="12" class="mb-4" align="center">
          <h1 class="display-2 mb-3">
            <div>Bem-vindo!</div>
            <!-- <div class="font-weight-bold my-4">Panorama Centro Automotivo</div> -->
          </h1>
          <p class="overline" style="font-size: 16px !important">
            Controle de Caixa Financeiro.
          </p>
        </v-col>
      </v-row>
    </z-container>
  </div>
</template>

<script>
export default {
  data: () => ({}),

  async mounted() {},

  methods: {},
};
</script>

<style>
.sublinhado {
  text-decoration: underline;
}
</style>
